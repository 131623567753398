<template>
	<!-- Global notification live region, render this permanently at the end of the document -->
	<div
		aria-live="assertive"
		class="pointer-events-none inset-0 flex items-end px-4 sm:items-start relative max-w-7xl mx-auto"
	>
		<div class="flex w-full flex-col items-center space-y-4 sm:items-end absolute right-[1.5rem]">
			<!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
			<transition
				enter-active-class="transform ease-out duration-300 transition"
				enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
				enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
				leave-active-class="transition ease-in duration-100"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<div
					v-if="show"
					class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-slate-900 ring-opacity-5"
					:class="{ 'my-6 sm:m-6': show }"
				>
					<div class="p-4">
						<div class="flex items-start">
							<div class="ml-3 w-0 flex-1 pt-0.5">
								<p class="text-sm font-medium text-slate-900">{{ message }}</p>
							</div>
							<div class="ml-4 flex flex-shrink-0">
								<button
									type="button"
									@click="closeModal()"
									class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent-500 focus:ring-offset-2"
									data-cy="close-toast-notification-button"
								>
									<span class="sr-only">Close</span>
									<XMarkIcon class="h-5 w-5" aria-hidden="true" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script setup>
import { ref, onUpdated, onMounted } from 'vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'

const show = ref(props.show)
const hideInMilliseconds = 3000

const props = defineProps({
	message: { type: String, required: true },
	show: { type: Boolean, required: false, default: false },
	onClose: { type: Function, required: false },
})

onMounted(() => {
	timeoutModal()
})

onUpdated(() => {
	show.value = props.show
	timeoutModal()
})

const closeModal = () => {
	show.value = false
	if (typeof onClose === 'function') onClose()
}

const timeoutModal = () => {
	if (show.value) {
		setTimeout(closeModal, hideInMilliseconds)
	}
}
</script>
<style lang="scss" scoped></style>
