import { ref } from 'vue'
import { useUserStore } from '../../stores/UserStore'
import Dictionary from './translations'

// by convention, composable function names start with "use"
export function useI18n() {
	const userStore = useUserStore()

	// state encapsulated and managed by the composable
	const langLocale = ref(userStore.user.langLocale)

	const t = (path: string) => {
		return Dictionary[path][langLocale.value] || Dictionary[path]['en']
	}

	const fmt = (path: string, interpolateObj: Record<string, string>) => {
		const regex = new RegExp(Object.keys(interpolateObj).join('|'), 'gi')
		let translation = t(path)

		if (Object.keys(interpolateObj).length) {
			translation = translation.replace(regex, (matched) => interpolateObj[matched])
		}

		return translation
			.replace(/<DomainName>/g, window.location.hostname)
			.replace(/<CoordinatorPhone>/g, '877-731-9733')
	}

	const tDate = (date: string) => {
		if (!date) return '-'

		const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
		const formalLocaleString: string | undefined =
			{
				en: 'en-US',
				es: 'es-ES',
			}[langLocale.value as string] || 'en-US'

		// @ts-ignore
		return new Date(date).toLocaleString(formalLocaleString, options)
	}

	return { langLocale, t, fmt, tDate }
}
