import { identifier } from '@babel/types'
import { router } from './main'
import { IntakeSteps } from './stores/IntakeStepStore'
import { MedEntry } from './stores/MedListStore'

export type Token = string | null

export type LangLocaleChoice = 'en' | 'es'

export interface User {
	email: string | null
	id: number | null
	token: string | null
	role: string | null
	consented: boolean | null
	langLocale: LangLocaleChoice
	dob: string | null
	first_name: string | null
	last_name: string | null
	phone_number: string | null
	address_line_1: string | null
	address_line_2: string | null
	city: string | null
	state: string | null
	zipcode: string | null
	jwt_refresh_timestamp: string | null
	loggedOutHome: string | null
}

const BASE_URL = '/'

export function getUser(): Promise<User> {
	return getData('current_user/', {}, true).then((res) => {
		const jwt: Token = res.headers.get('authorization')

		if (jwt) {
			storeToken(jwt)
		} else {
			deleteToken()
			localStorage.removeItem('patient_token')
		}

		return res.json().then((json) => {
			storeLangLocale(json.langLocale)
			return Promise.resolve(json)
		})
	})
}

export async function updateLanguageLocale(
	newLang: LangLocaleChoice,
	patientToken: string | null
): Promise<any> {
	if (newLang) {
		storeLangLocale(newLang)
	}
	return await putData('/current_user/lang_locale', {
		language_locale: newLang,
		patient_token: patientToken,
	})
}

interface EligibilityUpdateParams {
	status: string
	study_key: string
	user_id: number
	reason?: string | undefined | null
	note?: string | undefined | null
	source?: string | undefined | null
}

export function updateEligibility(params: EligibilityUpdateParams): Promise<void> {
	return putData(`api/user_study_eligibilities/${params.study_key}`, params)
		.then((res) => res.json())
		.catch((error) => {
			console.error(error)
		})
}

export function adminUpdateEligibility(params: EligibilityUpdateParams): Promise<void> {
	return putData(`admin_api/user_study_eligibilities/${params.study_key}`, params)
		.then((res) => res.json())
		.catch((error) => {
			console.error(error)
		})
}

export async function getUserStudiesForAdmin(id: number): Promise<any> {
	const response = await getData(`admin_api/users/studies/${id}`)

	return response.json()
}

export async function searchForUsersInAdmin(search: string): Promise<any> {
	const response = await getData(`admin_api/users?search=${search}`)
	return response.json()
}

export function signupUser(email: string, password: string): Promise<RegistrationResponse> {
	return postData('sign_up/', { user: { email, password } })
		.then((res) => res.json())
		.catch((error) => {
			return error
		})
}

export function getStoredToken() {
	return localStorage.getItem('token')
}

function storeToken(aToken: string) {
	return localStorage.setItem('token', aToken)
}

function deleteToken() {
	localStorage.removeItem('token')
}

function storeLangLocale(lang: LangLocaleChoice) {
	if (lang) {
		return localStorage.setItem('langLocale', lang)
	}
}

export interface RegistrationResponse {
	data: {
		email: string
		id: number
		consented: boolean
		langLocale: LangLocaleChoice
		dob: string | null
		first_name: string | null
		last_name: string | null
		phone_number: string | null
		address_line_1: string | null
		address_line_2: string | null
		state: string | null
		city: string | null
		zipcode: string | null
		jwt_refresh_timestamp: string | null
	}
	status: Record<string, string>
	error: string | null
}

export function loginUser(email: string, password: string): Promise<RegistrationResponse> {
	return postData('sign_in/', { user: { email, password } })
		.then((res) => {
			const jwt: Token = res.headers.get('authorization')
			if (jwt) storeToken(jwt)

			return res.json()
		})
		.catch((error) => {
			return error
		})
}

export async function logoutUser() {
	const response = await fetch('sign_out/', {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
	})

	deleteToken()

	return response.json()
}

async function getData(url = '', data = {}, skipAuth = false) {
	const response = await fetch(`${BASE_URL}${url}`, {
		method: 'GET',
		headers: {
			Authorization: `bearer ${getStoredToken()}`,
			'Content-Type': 'application/json',
		},
	})

	if (!skipAuth) {
		if (response.status === 401 || (response.redirected && response.url.match('/login'))) {
			window.location.href = '/login'
			throw 'Please log in before completing this action'
		}
	}

	if (response.status === 500) {
		window.location.href = '/500'
	}
	if (response.status === 404) {
		window.location.href = '/404'
	}

	const jwt: Token = response.headers.get('authorization')
	if (jwt) storeToken(jwt)

	return response
}

async function makeFetchReq(url = '', data = {}, method = 'POST') {
	const response = await fetch(`${url}`, {
		method: method,
		headers: {
			Authorization: `bearer ${getStoredToken()}`,
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		body: JSON.stringify(data),
	})

	const jwt: Token = response.headers.get('authorization')
	if (jwt) storeToken(jwt)

	return response
}

async function postData(url = '', data = {}) {
	return await makeFetchReq(url, data, 'POST')
}

async function putData(url = '', data = {}) {
	return await makeFetchReq(url, data, 'PUT')
}

export async function getUserStudies() {
	const response = await getData('api/studies/')

	return response.json()
}

export async function getStudyDefinition(key: string, locale: string): Promise<StudyDefinition> {
	const response = await getData(`api/study_definitions/${key}?locale=${locale}`)

	return response.json()
}

export async function getStudyDefinitionInformedConsentPreview(
	key: string | string[],
	token: string | string[],
	lang: string | string[]
): Promise<InformedConsentPreview> {
	const response = await getData(`api/study_definitions/${key}/informed_consent_preview/${token}?lang=${lang}`)

	if (response.status === 404) {
		window.location.href = '/404'
	}

	return response.json()
}

export async function getStudyDefinitionFaqs(key: string, token: string | null): Promise<FAQ[]> {
	const response = await getData(`api/study_definitions/${key}/faqs`)

	if (response.status === 404) {
		window.location.href = '/404'
	}

	return response.json()
}

export interface LandingPage {
	id: number
	page_title: string
	page_subtitle: string
	study_content: string
}

export interface ParticipantStatusPage {
	id: number
	body: string
	title: string
	page_type: string
}

export interface StudyDefinition {
	key: string
	title: string
	decline_confirmation: string
	decline_success_content: string
	decline_success_title: string
	faqs_questions: FAQ[]
	landing_pages: LandingPage[]
	zen_desk_url: string | null
	participant_status_pages: Record<string, ParticipantStatusPage>
	informed_consent: object | null
	verify_profile_overview: string | null
	informed_consent_page_title: string | null
	informed_consent_page_text: string | null
	what_is_informed_consent_title: string | null
	what_is_informed_consent_text: string | null
	informed_consent_form_html: string | null
	prescribing_info_link_url: string | null
	prescribing_info_link_text: string | null
	show_faqs_in_nav_bar: boolean
	theme: string
	study_logo_svg: string | null
	study_coordinator_phone: string | null
	investigator_full_name: string | null
	investigator_email: string | null
}

export interface Choice {
	id: number
	text: string
	value: string
	photo: string | null
}

export interface QuestionnaireQuestionLinkComplexCondition {
	key?: string
	equal?: string
	includes?: string
	excludes?: string
	when_answer_absent?: boolean
	date?: string
}

export interface QuestionnaireQuestionLink {
	link_to: { link_to_id: number; link_to_key: string }
	condition: {
		equal?: string
		includes?: string
		excludes?: string
		when_answer_absent?: boolean
		any?: QuestionnaireQuestionLinkComplexCondition[]
		all?: QuestionnaireQuestionLinkComplexCondition[]
	}
	cohort?: string
}

export interface QuestionnaireQuestion {
	choices: Choice[]
	links: QuestionnaireQuestionLink[]
	initial_question: boolean
	label: string
	header: string | undefined
	question_id: number
	question_key: string
	type: string
	node_id: number
	icon: string | undefined
	exclusive_question_answer_ids: number[]
	prelude_header?: string | null
	prelude_description?: string | null
	add_entry_button_label?: string | null
	none_button_label?: string | null
	finished_entering_button_label?: string | null
	usage_tip_content?: string | null
}

export type QuestionAnswer = {
	id?: number
	choices: Choice[]
	questionnaire_node_id: number
	question_key: string
	user_id: number | null
	status: string
	answer_method: 'participant' | 'auto' | 'unconfirmed_prepopulation'
	freetext: string | null
	date_answer: string | null
	medication_type?: 'medication' | 'vitamin'
	medication_entries: MedEntry[]
	answer_type:
		| 'QuestionAnswer::Selection'
		| 'QuestionAnswer::Date'
		| 'QuestionAnswer::Freetext'
		| 'QuestionAnswer::MedicationEntry'
		| 'QuestionAnswer::VitaminEntry'
		| 'QuestionAnswer::Display'
}

export interface Study {
	created_at: string
	global_study_definition_id: string
	id: number
	key: string
	title: string
	updated_at: string
}

export interface Survey {
	created_at: string
	global_survey_definition_id: string
	id: number
	key: string
	study_definition_id: number
	updated_at: string
	status: string
}

export interface UserStudyResponse {
	answers: QuestionAnswer[]
	survey: Survey
	study: Study
	questionnaire: QuestionnaireQuestion[]
	cohort: UserCohort
	eligibility: UserStudyEligibility
}

export interface UserCohort {
	status: string
	key: string
	question_key: string
	overview: string
}

export interface UserStudyEligibility {
	status: string
}

export interface FAQ {
	answer: string
	id: number
	key: string
	question: string
}

export interface InformedConsentPreview {
	informed_consent_form_html: string
}

export async function getUserStudy(data: { key: string }): Promise<UserStudyResponse> {
	const response = await getData(`api/studies/${data.key}`)

	return response.json()
}

export async function submitProgressToBackend(
	answers: QuestionAnswer[],
	user: User,
	survey: Survey
): Promise<any> {
	try {
		const res = await putData(`api/answers/${user.id}`, { answers, survey: { id: survey.id } })
		if (res.status === 401) {
			router.push('/login')
		}
	} catch (e) {
		console.error(e)
	}
}

export async function submitSurveyComplete(params: { user: User; survey: Survey }): Promise<any> {
	try {
		const res = await putData(`api/surveys/${params.survey.id}`, { survey: { status: 'complete' } })

		if (res.status === 201 && res.headers.get('Location')) {
			window.location.href = res.headers.get('Location') as string
			return
		}

		return res.json()
	} catch (e) {
		console.error(e)
	}
}

export async function getAdminUsers(params: { user: User }): Promise<any> {
	try {
		const res = await getData('admin_api/users', {}, true)
		if (res.status === 200) {
			return res.json()
		} else {
			router.push('/404')
		}
	} catch (e) {
		console.error(e)
	}
}

export async function getUserForShowPage(id: number): Promise<any> {
	try {
		const res = await getData(`admin_api/users/${id}`, {}, true)
		if (res.status === 200) {
			return res.json()
		} else {
			router.push('/404')
		}
	} catch (e) {
		console.error(e)
	}
}

export async function resetUserInformedConsent(params: {
	user_id: number
	consent_id: number
	status: string
}): Promise<any> {
	const response = await putData(
		`/admin_api/users/${params.user_id}/informed_consents/${params.consent_id}`,
		{
			status: params.status,
			consent_id: params.consent_id,
			user_id: params.user_id,
		}
	)
	return response.json()
}

export async function getUserInformedConsents(params: { user_id: number }): Promise<any> {
	const response = await getData(
		`admin_api/users/${params.user_id}/informed_consents/${params.user_id}`
	)
	return response.json()
}

export async function claimCompensation(params: {
	study_id: string
	compensation_id: string
}): Promise<any> {
	return await putData(`/api/user_study_compensations/${params.compensation_id}`, {
		study_id: params.study_id,
	})
}

export function trackEvent(data: Record<string, string | User>): void {
	postData(`/api/tracking_events`, { trackable: data })
}

export async function updateUserInAdmin(params: object, user_id: number): Promise<any> {
	const res = await putData(`/admin_api/users/${user_id}`, params)
	return res.json()
}

export async function updateUserProfile(data: {
	email: string
	dob: string
	firstName: string
	lastName: string
	id: string
	token: string
	password: string
	phoneNumber: string
	langLocale: LangLocaleChoice
	addressLine1: string | null
	addressLine2: string | null
	city: string | null
	state: string | null
	zipcode: string | null
	source: string | null
	studyKey: string
}) {
	const res = await putData(`/user_profiles/${data.id}`, { data })

	return res.json()
}

export async function getProfileInfo(data: { token: string; key: string; source: string | null }) {
	const res = await getData(`user_profiles/${data.token}?key=${data.key}&source=${data.source}`)
	return res.json()
}

export function requestPasswordResetLink(data: Record<'email', string>): void {
	postData(`/users/passwords`, data)
}

export async function requestEmailResetLink(data: {
	email: Record<'email', string>
	user_id: string
}) {
	const response = await postData(`/users/emails`, data)
	return response
}
interface ResetUserPasswordParams {
	token: string
	password: string
	language_locale: LangLocaleChoice
}
export async function resetUserPassword(
	data: ResetUserPasswordParams
): Promise<{ response: Response; json: Record<string, string[]> }> {
	const response = await putData(`/users/passwords/${data.token}`, data)
	const json = await response.json()

	return { response, json }
}
interface ResetUserEmailParams {
	user_id: number
	confirmation_token: string
	language_locale: LangLocaleChoice
}
export async function resetUserEmail(
	data: ResetUserEmailParams
): Promise<{ response: Response; json: Record<string, string[]> }> {
	const response = await putData(`/users/emails/${data.user_id}`, data)
	const json = await response.json()

	return { response, json }
}

interface LandingPageResponse {
	landing_page: LandingPage | null
	intake_steps: IntakeSteps | null
	redirect: string | null
}

export async function getLandingPageData(data: {
	token: string
	key: string
	lang: string
	source: string
}): Promise<LandingPageResponse | null> {
	const { key, lang, token, source } = data

	const response = await getData(
		`api/landing_pages/${key}?token=${token}&locale=${lang}&source=${source}`,
		{},
		true
	)

	if (response.status === 401 || (response.redirected && response.url.match('/login'))) {
		await router.push('/login')
		throw 'Please log in before completing this action'
	} else if (response.redirected) {
		const matched_decline = response.url.match('/success/decline/(.*)')
		if (matched_decline) {
			// if we don't do this here (and a router.push in the caller), we end up with a
			// state where we redirect to /login after we hit the /success/decline page
			return { redirect: `/success/decline/${matched_decline[1]}`, landing_page: null, intake_steps: null }
		}

		if (response.url.match('/')) {
			window.location.href = '/'
			await router.push('/')
			return { redirect: '/', landing_page: null, intake_steps: null }
		}

		// can't parse response below most likely, so we're returning here
		return null
	}

	return response.json()
}

export async function getAdminParticipantProgressions(data: {
	study_definition_key?: string
	sort?: string
	filters?: string
	search?: string | null
}) {
	const { study_definition_key, sort, filters, search } = data

	const params = new URLSearchParams()
	if (sort) params.set('sort', sort)
	if (filters) params.set('filters', filters)
	if (search) params.set('search', search)

	const response = await getData(
		`admin_api/studies/${study_definition_key}/participant_progressions?${params.toString()}`,
		{ sort }
	)

	if (response.status === 401) {
		await router.push('/login')
	}

	return response.json()
}

export async function getAdminCurrentUserPermissableStudyDefinitions(data: { user_id?: number }) {
	let { user_id } = data
	user_id = user_id || 0

	const response = await getData(`admin_api/users/${user_id}/study_definitions`)

	if (response.status === 401) {
		await router.push('/login')
	}

	return response.json()
}

export async function updateAdminParticipantProgressions(data: {
	study_definition_key: string
	progress_record_id: string
	update_data: Record<string, string | null>
}) {
	const { study_definition_key, progress_record_id, update_data } = data

	const response = await putData(
		`admin_api/studies/${study_definition_key}/participant_progressions/${progress_record_id}`,
		update_data
	)

	if (response.status === 401) {
		await router.push('/login')
	}

	return response.json()
}

export async function getCurrentStudy() {
	const response = await postData('/api/study_definitions/current')
	return response.json()
}

export async function adminUpdateParticipantEnrollment(token: string) {
	const response = await putData(`/admin_api/study_enrollments/${token}`)
	return response.json()
}

export async function adminGetStudyDefinitions() {
	const response = await getData(`admin_api/study_definitions`)
	return response.json()
}

interface iAdminStudyDefUpdateParams {
	study_coordinator_email: string
	zen_desk_url: string
	enrollment_expiration_days: number
	survey_cadence_days: number
	survey_cadence_strategy: string
	study_url: string
	show_faqs_in_nav_bar: boolean
}
export async function adminUpdateStudyDefinition(
	studyDefinitionId: number,
	updateData: iAdminStudyDefUpdateParams
) {
	const response = await putData(`admin_api/study_definitions/${studyDefinitionId}`, updateData)
	return response.json()
}

export async function updateIntakeStep(
	intakeStepId: number | undefined | string,
	token: string,
	key: string,
	status?: string
) {
	if (!intakeStepId) return

	const params = new URLSearchParams()
	params.set('token', token)
	params.set('key', key)
	if (status) params.set('status', status)

	const response = await putData(`/api/intake_steps/${intakeStepId}?${params.toString()}`)

	return response.json()
}

export interface ApiRxTerm {
	rxcui: number
	generic_rxcui: number | null
	tty: string
	rxn_dose_form: string
	full_generic_name: string
	brand_name: string
	display_name: string
	route: string
	new_dose_form: string
	strength: string
	suppress_for: string
	display_name_synonym: string
	is_retired: string
	sxdg_rxcui: number
	sxdg_tty: string
	sxdg_name: string
	psn: string
	rowid: number
	similarity_score: number
}

export async function searchRxTerms(query: string): Promise<ApiRxTerm[]> {
	const queryParams = new URLSearchParams()
	queryParams.set('query', query)

	const response = await getData(`api/rx_terms?${queryParams.toString()}`)
	return response.json()
}

export async function getRxTermsViaSxdgRxcui(sxdgRxcui: string): Promise<ApiRxTerm> {
	const response = await getData(`api/rx_terms/${sxdgRxcui}`)
	return response.json()
}

export interface MedEntryQuestionAnswer extends MedEntry {
	question_answer_id: number
	user_id: number
	user_email: string
	needs_disambiguation: boolean
}
export interface AdminGetMedicationEntriesResponse {
	answers: MedEntryQuestionAnswer[]
}
export async function adminGetMedicationEntries(
	studyDefinitionId: number,
	data: Record<string, string>
): Promise<AdminGetMedicationEntriesResponse> {
	const queryParams = new URLSearchParams()
	if (data.userId) queryParams.set('user_id', data.userId)
	if (data.only_meds) queryParams.set('only_meds', data.only_meds)

	const response = await getData(
		`admin_api/studies/${studyDefinitionId}/medication_entries?${queryParams.toString()}`
	)
	return response.json()
}

export async function adminGetMedicationEntry(
	questionAnswerId: number
): Promise<AdminGetMedicationEntriesResponse> {
	const response = await getData(`admin_api/studies/0/medication_entries/${questionAnswerId}`)
	return response.json()
}

export async function adminUpdateMedicationEntry(
	idData: { questionAnswerId: number; studyDefinitionId: number },
	params: Record<string, string>[]
): Promise<AdminGetMedicationEntriesResponse> {
	const { questionAnswerId, studyDefinitionId } = idData
	const response = await putData(
		`admin_api/studies/${studyDefinitionId}/medication_entries/${questionAnswerId}`,
		params
	)

	return response.json()
}

export interface ApiUserCohort {
	id: number
	study_definition_id: number
	study_definition_key: string
	key: string
	status: string
	survey_id: number
	survey_status: number
	survey_available_at: Date
	survey_missed_at: Date
	survey_closed_at: Date
	cohort_update_options: string[]
}

export async function adminGetUserCurrentCohorts(
	user_id: number
): Promise<{ cohorts: ApiUserCohort[] }> {
	const response = await getData(`admin_api/users/${user_id}/study_cohorts`, {})

	return response.json()
}

export async function adminUpdateUserCohort(data: {
	cohort: ApiUserCohort
	note: string
	user_id: number
}) {
	const { cohort, note, user_id } = data
	const params = { note }

	const response = await putData(`admin_api/users/${user_id}/study_cohorts/${cohort.id}`, params)

	return response.json()
}

export async function adminGetEmails(data: { studyDefKey: any }) {
	const { studyDefKey } = data

	const queryParams = new URLSearchParams()

	const response = await getData(
		`admin_api/studies/${studyDefKey}/emails?${queryParams.toString()}`
	)
	return response.json()
}

export async function adminShowEmail(data: { studyDefId: number, emailReceiptIdentifier: string }) {
	const { emailReceiptIdentifier, studyDefId } = data

	const queryParams = new URLSearchParams()

	const response = await getData(
		`admin_api/studies/${studyDefId}/emails/${emailReceiptIdentifier}?${queryParams.toString()}`
	)
	return response.json()
}

export async function adminGetUserQuestionnaires(user_id: number, studyDefId: string) {
	const queryParams = new URLSearchParams()
	if (studyDefId) queryParams.set('study_definition_id', studyDefId)

	const response = await getData(
		`admin_api/users/${user_id}/questionnaire_rollup_reports?${queryParams.toString()}`
	)

	return response.json()
}

export async function adminDownloadUserQuestionnairePdf(questionnaire: any) {
	const { id, study_definition_id, user_id, questionnaire_position, study_definition_key } = questionnaire

	const queryParams = new URLSearchParams()
	queryParams.set('study_definition_id', study_definition_id)

	const response = await getData(
		`admin_api/users/${user_id}/questionnaire_rollup_reports/${id}?${queryParams.toString()}`
	)

	const blob = await response.blob()
	const url = window.URL.createObjectURL(blob);
	const link = document.createElement('a')
	document.body.appendChild(link)
	link.style.display = 'none'
	link.href = url
	link.download = `${study_definition_key}--${questionnaire_position}--user${user_id}.pdf`
	link.click()
	window.URL.revokeObjectURL(url)
}
