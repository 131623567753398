import { zenDeskUrl } from './constants'
import { useUserStore } from './stores/UserStore'

export function goToZenDesk(url: string | null) {
	url ||= zenDeskUrl

	// replace /en-us/ with /es/ if Spanish (instead of maintaining multiple URLS)
	const userStore = useUserStore()
	const langLocale = userStore.user?.langLocale

	if (langLocale === 'es') {
		url = url.replace('en-us', 'es')
	}

	window.open(url, '_blank')
}
