<template>
  <TransitionRoot as="template" :show="props.open">
    <Dialog as="div" class="relative z-40" @close="closeModal()">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-x-0 inset-y-5 sm:inset-y-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div>
                <div class="mx-auto flex h-14 w-14 items-center justify-center rounded-full">
                  <QuestionMarkCircleIcon class="block inline square-96 bg-accent-base text-white rounded-full border-4 border-accent-base" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-slate-900">{{ t('haveAQuestion') }}</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500"
										v-html="fmt('askQuestionTextIfStudyJoined', {})"/>
                  </div>
									  <div class="mt-2">
                    <p class="text-sm text-gray-500">{{ fmt('askQuestionTextIfUnableToLogin', {}) }}</p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button class="inline-flex w-full justify-center rounded-md bg-accent-base px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accent-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-600 sm:col-start-2" @click="goToLogin()">{{ t('logIn') }}</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0" @click="closeModal()" ref="cancelButtonRef">{{ t('cancel') }}</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { QuestionMarkCircleIcon } from '@heroicons/vue/20/solid'
import { useI18n } from '../../services/i18n/useI18n'

const { t, fmt } = useI18n()

const props = defineProps({
	open: { type: Boolean, required: false, default: false },
	embedded: { type: Boolean, default: false }
})

const emit = defineEmits(['closeModal', 'goToLogin'])

const closeModal = () => {
	emit('closeModal')
}

const goToLogin = () => {
	emit('goToLogin')
}
</script>
