import { defineStore } from 'pinia'
import {
	FAQ,
	getStudyDefinition,
	LandingPage,
	ParticipantStatusPage,
	StudyDefinition,
} from '../api'

type ParticipantStatusPageRecord = Record<string, ParticipantStatusPage>
type EmptyObject = Record<string, never>

export type IStudyDefinitionState = {
	key: string | null
	title: string | null
	decline_confirmation: string | null
	decline_success_content: string | null
	decline_success_title: string | null
	faqs_questions: FAQ[] | []
	landing_pages: LandingPage[] | []
	zen_desk_url: string | null
	participant_status_pages: ParticipantStatusPageRecord | EmptyObject
	informed_consent: object | null
	verify_profile_overview: string | null
	informed_consent_page_title: string | null
	informed_consent_page_text: string | null
	what_is_informed_consent_title: string | null
	what_is_informed_consent_text: string | null
	informed_consent_form_html: string | null
	prescribing_info_link_url: string | null
	prescribing_info_link_text: string | null
	show_faqs_in_nav_bar: boolean
	study_logo_svg: string | null
	study_coordinator_phone: string | null
	investigator_full_name: string | null
	investigator_email: string | null
}

export const useStudyDefinitionStore = defineStore('StudyDefintionStore', {
	// initial state
	state: () => {
		return {
			key: null,
			title: null,
			decline_confirmation: null,
			decline_success_content: null,
			decline_success_title: null,
			faqs_questions: [],
			landing_pages: [],
			zen_desk_url: null,
			participant_status_pages: {},
			informed_consent: null,
			verify_profile_overview: null,
			informed_consent_page_title: null,
			informed_consent_page_text: null,
			what_is_informed_consent_title: null,
			what_is_informed_consent_text: null,
			informed_consent_form_html: null,
			prescribing_info_link_url: null,
			prescribing_info_link_text: null,
			show_faqs_in_nav_bar: false,
			study_logo_svg: null,
			study_coordinator_phone: null,
			investigator_full_name: null,
			investigator_email: null,
		} as IStudyDefinitionState
	},

	// Same as methods in vue components
	actions: {
		async hydrateStudyDefinition(key: string, locale: string) {
			this.flush()
			const res: StudyDefinition = await getStudyDefinition(key, locale)
			this.setTheme(res)
			this.setStudyDefinitionAttributes(res)
		},
		flush() {
			this.setKey(null)
			this.setTitle(null)
			this.setDeclineConfirmation(null)
			this.setDeclineContent(null)
			this.setDeclineTitle(null)
			this.setFaqs([])
			this.setLandingPages([])
			this.setParticipantStatusPages({})
			this.setZenDeskUrl(null)
			this.setInformedConsent(null)
			this.setVerifyProfileOverview(null)
			this.setShowFaqs(false)
			this.setStudyLogoSvg(null)
		},
		setStudyDefinitionAttributes(params: {
			key: string
			title: string
			decline_confirmation: string
			decline_success_content: string
			decline_success_title: string
			faqs_questions: FAQ[]
			landing_pages: LandingPage[]
			zen_desk_url: string | null
			participant_status_pages: Record<string, ParticipantStatusPage>
			informed_consent: object | null
			verify_profile_overview: string | null
			informed_consent_page_title: string | null
			informed_consent_page_text: string | null
			what_is_informed_consent_title: string | null
			what_is_informed_consent_text: string | null
			informed_consent_form_html: string | null
			prescribing_info_link_url: string | null
			prescribing_info_link_text: string | null
			show_faqs_in_nav_bar: boolean
			study_logo_svg: string | null
			study_coordinator_phone: string | null
			investigator_full_name: string | null
			investigator_email: string | null
		}) {
			if (params.key) this.setKey(params.key)
			if (params.title) this.setTitle(params.title)
			if (params.decline_confirmation) this.setDeclineConfirmation(params.decline_confirmation)
			if (params.decline_success_content) this.setDeclineContent(params.decline_success_content)
			if (params.decline_success_title) this.setDeclineTitle(params.decline_success_title)
			if (params.faqs_questions) this.setFaqs(params.faqs_questions)
			if (params.landing_pages) this.setLandingPages(params.landing_pages)
			if (params.zen_desk_url) this.setZenDeskUrl(params.zen_desk_url)
			if (params.participant_status_pages) {
				this.setParticipantStatusPages(params.participant_status_pages)
			}
			if (params.informed_consent) this.setInformedConsent(params.informed_consent)
			if (params.verify_profile_overview) {
				this.setVerifyProfileOverview(params.verify_profile_overview)
			}
			if (params.informed_consent_page_title) {
				this.setInformedConsentPageTitle(params.informed_consent_page_title)
			}
			if (params.informed_consent_page_text) {
				this.setInformedConsentPageText(params.informed_consent_page_text)
			}
			if (params.what_is_informed_consent_title) {
				this.setWhatIsInformedConsentTitle(params.what_is_informed_consent_title)
			}
			if (params.what_is_informed_consent_text) {
				this.setWhatIsInformedConsentText(params.what_is_informed_consent_text)
			}
			if (params.informed_consent_form_html) {
				this.setInformedConsentFormHtml(params.informed_consent_form_html)
			}
			if (params.prescribing_info_link_url) {
				this.setPrescribingInfoLinkUrl(params.prescribing_info_link_url)
			}
			if (params.prescribing_info_link_text) {
				this.setPrescribingInfoLinkText(params.prescribing_info_link_text)
			}
			if (params.show_faqs_in_nav_bar) this.setShowFaqs(params.show_faqs_in_nav_bar)
			if (params.study_logo_svg) this.setStudyLogoSvg(params.study_logo_svg)
			if (params.investigator_full_name) this.setInvestigatorFullName(params.investigator_full_name)
			if (params.investigator_email) this.setInvestigatorEmail(params.investigator_email)
			if (params.study_coordinator_phone) {
				this.setStudyCoordinatorPhone(params.study_coordinator_phone)
			}
		},
		setKey(key: string | null) {
			this.key = key
		},
		setTitle(title: string | null) {
			this.title = title
		},
		setDeclineConfirmation(text: string | null) {
			this.decline_confirmation = text
		},
		setDeclineContent(text: string | null) {
			this.decline_success_content = text
		},
		setDeclineTitle(text: string | null) {
			this.decline_success_title = text
		},
		setFaqs(faqs: FAQ[] | []) {
			this.faqs_questions = faqs
		},
		setLandingPages(landings: LandingPage[] | []) {
			this.landing_pages = landings
		},
		setZenDeskUrl(text: string | null) {
			this.zen_desk_url = text
		},
		setParticipantStatusPages(pages: ParticipantStatusPageRecord | EmptyObject) {
			this.participant_status_pages = pages
		},
		setInformedConsent(informedConsent: object | null) {
			this.informed_consent = informedConsent
		},
		setVerifyProfileOverview(text: string | null) {
			this.verify_profile_overview = text
		},
		setInformedConsentPageTitle(text: string | null) {
			this.informed_consent_page_title = text
		},
		setInformedConsentPageText(text: string | null) {
			this.informed_consent_page_text = text
		},
		setWhatIsInformedConsentTitle(text: string | null) {
			this.what_is_informed_consent_title = text
		},
		setWhatIsInformedConsentText(text: string | null) {
			this.what_is_informed_consent_text = text
		},
		setInformedConsentFormHtml(text: string | null) {
			this.informed_consent_form_html = text
		},
		setPrescribingInfoLinkUrl(text: string | null) {
			this.prescribing_info_link_url = text
		},
		setPrescribingInfoLinkText(text: string | null) {
			this.prescribing_info_link_text = text
		},
		setShowFaqs(showFaqsInNavBar: boolean) {
			this.show_faqs_in_nav_bar = showFaqsInNavBar
		},
		setTheme(studyDef: StudyDefinition) {
			const theme = studyDef.theme || 'default'
			document.body?.setAttribute('data-theme', theme)
		},
		setStudyLogoSvg(svgString: string | null) {
			const lockedStudyLogo = document?.getElementById('app')?.dataset?.studyLogoSvg
			this.study_logo_svg = lockedStudyLogo || svgString
		},
		setInvestigatorFullName(fullName: string | null) {
			this.investigator_full_name = fullName
		},
		setInvestigatorEmail(email: string | null) {
			this.investigator_email = email
		},
		setStudyCoordinatorPhone(phoneString: string | null) {
			this.study_coordinator_phone = phoneString
		},
	},

	// getters: same as computed properties in vue components
})
