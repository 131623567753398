interface Locales {
	en: string
	es: string
}

type TranslationDictionary = Record<string, Locales>

export default {
	'login/signInLabel': {
		en: 'Sign in to your account',
		es: 'Inicie sesión en su cuenta', // LL
	},
	askQuestionButton: {
		en: 'Ask a Question',
		es: 'Hacer una pregunta', // LL for "Ask a question"
	},
	haveAQuestion: {
		en: 'Have a Question?',
		es: '¿Tiene alguna pregunta?', // LL
	},
	askQuestionTextIfStudyJoined: {
		en: `If you are already participating in the study, you can submit questions by logging in and then choosing "<u>Ask a Question</u>"`,
		es: `Si ya está participando en el estudio, puede enviar sus preguntas iniciando sesión y luego eligiendo "<u>Hacer una pregunta</u>".`, // LL - English
	},
	askQuestionTextIfUnableToLogin: {
		en: `If you are unable to log in or haven't joined the study, you can submit questions by email at StudyCoordinator@<DomainName> or give us a call at <CoordinatorPhone> (available ET business hours).`,
		es: 'Si no puede iniciar sesión o no se unió al estudio, puede enviar sus preguntas por correo electrónico a StudyCoordinator@<DomainName> o llámenos al <CoordinatorPhone> (horario laborable ET disponible).', // LL
	},
	askQuestionTextIfUnableToLoginOrGiveUsACallAt: {
		en: 'or give us a call at',
		es: 'o llámenos al', // LL
	},
	'navbar/startAQuestionnaireOption': {
		en: 'Start a Questionnaire',
		es: 'Comenzar un cuestionario', // LL
	},
	'navbar/yourProfileOption': {
		en: 'Your Profile',
		es: 'Su perfil', // LL
	},
	userProfile: {
		en: 'User Profile',
		es: 'Perfil del usuario', // LL
	},
	profile: {
		en: 'Profile',
		es: 'Perfil', // LL
	},
	allRightsReserved: {
		en: 'All rights reserved',
		es: 'Todos los derechos reservados', // LL
	},
	privacyPolicy: {
		en: 'Privacy Policy',
		es: 'Política de privacidad', // LL
	},
	updateProfile: {
		en: 'Update Profile',
		es: 'Actualizar perfil', // LL
	},
	changeSettings: {
		en: 'Change setting and preferences.',
		es: 'Cambiar la configuración y las preferencias.', // LL
	},
	signIn: {
		en: 'Sign In',
		es: 'Iniciar sesión', // LL
	},
	signOut: {
		en: 'Sign Out',
		es: 'Cerrar sesión', // LL
	},
	completeProfile: {
		en: 'Complete Profile',
		es: 'Completar perfil', // LL
	},
	verifyProfile: {
		en: 'Verify Profile',
		es: 'Verificar perfil', // LL
	},
	verificationMessage: {
		en: "We were able to pull this information from your provider's record, but please update any information that may be incorrect.",
		es: 'Pudimos extraer esta información del registro de su proveedor, pero actualice cualquier información que pueda ser incorrecta.', // LL
	},
	firstName: {
		en: 'First Name',
		es: 'Primer Nombre', // LL
	},
	lastName: {
		en: 'Last Name',
		es: 'Apellido', // LL
	},
	name: {
		en: 'Name',
		es: 'Nombre', // LL
	},
	email: {
		en: 'Email',
		es: 'Correo electrónico', // LL
	},
	confirmEmail: {
		en: 'Confirm Email',
		es: 'Confirmar correo electrónico', // LL
	},
	emailRequired: {
		en: 'Email required',
		es: 'Correo electrónico necesario', // LL
	},
	emailBadFormat: {
		en: 'Unrecognized email format',
		es: 'Formato de correo electrónico no reconocido', // LL
	},
	emailConfirmRequired: {
		en: 'Email Confirmation required',
		es: 'Se necesita confirmación del correo electrónico', // LL
	},
	emailConfirmMustMatch: {
		en: 'Email and Confirmation must match',
		es: 'El correo electrónico y la confirmación deben coincidir', // LL
	},
	emailUpdated: {
		en: 'Your email address has been updated',
		es: 'Su dirección de correo electrónico se ha actualizado', // LL
		// es: 'Se actualizó su dirección de correo electrónico', // LL -- a prior translation
	},
	emailUpdateFailed: {
		en: 'There was an error, and your email address has not been updated',
		es: 'Hubo un error y su dirección de correo electrónico no se actualizó', // LL
		// es: 'Se ha producido un error y su dirección de correo electrónico no se ha actualizado', // LL -- a prior translation
	},
	changeEmailAddress: {
		en: 'Change Email Address',
		es: 'Canbiar dirección de correo electrónico', // LL
	},
	checkYourEmail: {
		en: 'Check your email',
		es: 'Consultar su correo electrónico', // LL
		// es: 'Revise su correo electrónico', //  -- a prior translation
	},
	role: {
		en: 'Role',
		es: 'Papel', // LL
		// es: 'Puesto', // LL -- a prior translation
	},
	emailAddress: {
		en: 'Email Address',
		es: 'Dirección de correo electrónico', // LL
	},
	dob: {
		en: 'Date of Birth',
		es: 'Fecha de nacimiento', // LL
	},
	phone: {
		en: 'Phone Number',
		es: 'Teléfono', // LL
	},
	mobilePhone: {
		en: 'Phone Number (Mobile)',
		es: 'Teléfono (móvil)', // LL
	},
	phoneRequired: {
		en: 'Phone Required',
		es: 'El teléfono es obligatorio', // LL
		// es: 'Debe dar un número de teléfono', // LL -- a prior translation
	},
	phoneMustBeTenDigits: {
		en: 'Phone number must be ten digits',
		es: 'El número de teléfono debe tener diez dígitos', // LL
	},
	addressLine1: {
		en: 'Mailing Address Line 1',
		es: 'Dirección postal linea 1', // LL
	},
	addressRequired: {
		en: 'Address required',
		es: 'La dirección es obligatoria', // LL
	},
	addressLine2: {
		en: 'Mailing Address Line 2',
		es: 'Dirección postal linea 2', // LL
	},
	city: {
		en: 'City',
		es: 'Ciudad', // LL
	},
	cityRequired: {
		en: 'City required',
		es: 'La ciudad es obligatoria', // LL
	},
	state: {
		en: 'State',
		es: 'Estado', // LL
	},
	stateRequired: {
		en: 'State required',
		es: 'El estado es obligatorio', // LL
	},
	zipcode: {
		en: 'ZIP/Postal Code',
		es: 'Código postal/ZIP', // LL
		// es: 'Código postal', // LL -- a prior translation
	},
	zipcodeRequired: {
		en: 'Zipcode required',
		es: 'El código postal es obligatorio', // LL
	},
	zipcodeMustBeFiveDigits: {
		en: 'Zipcode must be five digits',
		es: 'El código postal debe tener cinco dígitos',
	},
	zipcodeMustContainOnlyNumbers: {
		en: 'Zipcode most contain only numbers',
		es: 'La mayoría de los códigos postales solo contienen números',
	},
	language: {
		en: 'Language',
		es: 'Idioma', // LL
	},
	users: {
		en: 'Users',
		es: 'Usuarios', // LL
	},
	listOfUsers: {
		en: 'A list of all the users including their name, title, email and role.',
		es: 'Hacer una lista de todos los usuarios, incluyendo su nombre, puesto, correo electrónico y papel.', // LL
		// es: 'Hacer una lista de todos los usuarios, incluyendo su nombre, cargo, correo electrónico y función.', // LL -- a prior translation
	},
	edit: {
		en: 'Edit',
		es: 'Editar', // LL
	},
	'landing/thankYou': {
		en: 'Welcome! You may qualify to participate in this study.',
		es: '¡Bienvenido! Puede calificar para participar en este estudio.', // LL
	},
	optOut: {
		en: "I don't want to participate",
		es: 'No quiero participar', // LL
	},
	goBack: {
		en: 'Go Back',
		es: 'Regresar', // LL
	},
	backToQuestionnaire: {
		en: 'Go Back',
		es: 'Regresar', // LL
	},
	goToLogin: {
		en: 'Go to Log In',
		es: 'Ir al inicio de sesión', // LL
	},
	logIn: {
		en: 'Login',
		es: 'Inicio de sesión', // LL
	},
	password: {
		en: 'Password',
		es: 'Contraseña', // LL
	},
	confirmPassword: {
		en: 'Confirm Password',
		es: 'Confirmar contraseña.', // LL
	},
	createPassword: {
		en: 'Password',
		es: 'Crear contraseña', // LL
	},
	changePassword: {
		en: 'Change Password',
		es: 'Cambiar contraseña', // LL
	},
	passwordRequired: {
		en: 'Password required',
		es: 'Se necesita la contraseña', // LL
	},
	passwordConfirmRequired: {
		en: 'Password Confirmation required',
		es: 'Se necesita la confirmación de la contraseña', // LL
	},
	passwordConfirmMustMatch: {
		en: 'Password and Confirmation must match',
		es: 'La contraseña y la confirmación deben coincidir', // LL
	},
	passwordTooShort: {
		en: 'Password must be at least 8 characters',
		es: 'La contraseña debe tener al menos 8 caracteres', // LL
	},
	previewInformedConsentForm: {
		en: 'Preview Informed Consent Form',
		es: 'Vista previa del formulario de consentimiento informado',
	},
	joinStudy: {
		en: 'Sign Informed Consent Form',
		es: 'Firmar el formulario de consentimiento informado', // LL
		// es: 'Firme el formulario de consentimiento informado', // LL -- a prior translation
	},
	startQuestionnaire: {
		en: 'Start Questionnaire',
		es: 'Iniciar el cuestionario', // LL
	},
	submitQuestionnaire: {
		en: 'Submit Questionnaire',
		es: 'Enviar el cuestionario', // LL
	},
	backToPreviousQuestion: {
		en: 'Back to Previous Question',
		es: 'Regresar a la pregunta anterior', // LL
	},
	done: {
		en: 'Done',
		es: 'Listo', // LL
	},
	submit: {
		en: 'Submit',
		es: 'Enviar', // LL
	},
	frequentlyAskedQuestions: {
		en: 'Frequently Asked Questions',
		es: 'Preguntas frecuentes', // LL
	},
	docusignNameConsent: {
		en: 'By clicking the Sign Informed Consent Form button above, you are consenting for us to request your name from your Electronic Health Record and for us to contact you using the information you provide us. If you choose not to participate in the study or if you do not qualify, we will remove your information 90 days after your invitation was sent.',
		es: 'Al hacer clic en el botón “Sign Informed Consent Form” (Firmar el formulario de consentimiento informado) que está arriba, nos da su consentimiento para que pidamos su nombre del expediente médico electrónico y para que nos comuniquemos con usted usando la información que nos da. Si decide no participar en el estudio o si no califica, quitaremos su información 90 días después de enviarle la invitación.', // LL
		// es: 'Haciendo clic en el botón “Sign Informed Consent Form” (Firmar el formulario de consentimiento informado) que está arriba, nos da su consentimiento para que pidamos su nombre del expediente médico electrónico y para que nos comuniquemos con usted usando la información proporcionada. Si decide no participar en el estudio o si no califica, quitaremos su información 90 días después de enviarle la invitación.', // LL - prior translation
	},
	cannotFindAnswer: {
		en: "Can't find the answer you're looking for?",
		es: '¿No puede encontrar la respuesta que está buscando?', // LL
	},
	addUser: {
		en: 'Add User',
		es: 'Agregar usuario', // LL
	},
	editProfile: {
		en: 'Edit Profile Information',
		es: 'Editar información del perfil', // LL
	},
	eligibilityFor: {
		en: 'Eligibility for',
		es: 'Elegibilidad para', // LL
	},
	updateEligibilityText: {
		en: "Update the user's eligibility for a study",
		es: 'Actualizar la elegibilidad del usuario para un estudio', // LL
	},
	markEligible: {
		en: 'Mark Eligible',
		es: 'Marcar como elegible', // LL
	},
	markDeclined: {
		en: 'Mark Declined',
		es: 'Marcar como rechazado', // LL
	},
	markCompleted: {
		en: 'Mark Completed',
		es: 'Marcar completado', // LL
	},
	markIneligible: {
		en: 'Mark Ineligible',
		es: 'Marcar como no elegible', // LL
	},
	lostToFollowUp: {
		en: 'Lost to Follow-Up',
		es: 'Perdió el seguimiento', // LL
	},
	willRemoveInformedConsent: {
		en: 'This will remove the users informed consent for a study, and will require the user to sign another consent form through Docusign',
		es: 'Esto quitará el consentimiento informado de los usuarios para un estudio y será necesario que el usuario firme otro formulario de consentimiento por medio de Docusign', // LL
	},
	resetConsent: {
		en: 'Reset Consent',
		es: 'Restablecer el consentimiento', // LL
	},
	'resetPassword/sendResetLinkDesc': {
		en: "Enter your user account's email address and we will send you a password reset link",
		es: 'Escriba la dirección de correo electrónico verificada de su cuenta y le enviaremos un enlace para restablecer la contraseña.', // LL
	},
	enterYourEmailAddress: {
		// UNUSED
		en: 'Enter your email address',
		es: 'Escriba su dirección de correo electrónico', // LL
	},
	enterNewEmailAddress: {
		en: 'Enter new email address',
		es: 'Escriba su dirección de correo electrónico', // LL
	},
	sendPasswordResetEmail: {
		en: 'Send password reset email',
		es: 'Enviar correo electrónico para restablecer la contraseña', // LL
	},
	'resetPassword/doPasswordReset': {
		en: 'Reset your password',
		es: 'Restablecer su contraseña', // LL
	},
	resetPassword: {
		en: 'Reset password',
		es: 'Restablecer la contraseña', // LL
	},
	'resetPassword/resetLinkIsSent': {
		en: 'Your reset password link has been sent to your email if your email is associated with a user account on file. Please use the Ask a Question button in the header if you need any assistance',
		es: 'Se ha enviado el enlace para restablecer la contraseña a tu correo electrónico si tu dirección de correo está asociada a una cuenta de usuario en nuestro sistema. Si necesitas ayuda, por favor utiliza el botón "Hacer una pregunta" en la cabecera.',
	},
	goHome: {
		en: 'Go home',
		es: 'Ir al inicio', // LL
	},
	checkOutUrlAndTryAgain: {
		en: 'Check the URL in the address bar and try again',
		es: 'Verifique la URL en la barra de direcciones e inténtelo de nuevo', // LL
	},
	pageNotFound: {
		en: 'Page not found',
		es: 'No se encontró la página', // LL
	},
	anErrorHasOcurred: {
		en: 'An error has occurred',
		es: 'Se produjo un error', // LL
	},
	developmentTeamHasBeenNotified: {
		en: 'The development team has been notified',
		es: 'Se notificó al equipo de desarrollo', // LL
	},
	forgotPassword: {
		en: 'Forgot your password?',
		es: '¿Olvidó su contraseña?', // LL
	},
	passwordRequiresAtLeastOneLowercaseChar: {
		en: 'Password must contain at least 1 lowercase character',
		es: 'La contraseña debe tener al menos 1 carácter en minúscula', // LL
	},
	passwordRequiresAtLeastOneUppercaseChar: {
		en: 'Password must contain at least 1 uppercase character',
		es: 'La contraseña debe tener al menos 1 carácter en mayúscula', // LL
	},
	passwordRequiresANumber: {
		en: 'Password must contain at least 1 number character',
		es: 'La contraseña debe tener al menos 1 carácter numérico', // LL
	},
	passwordRequiresAtLeast1SpecialChar: {
		en: 'Password must contain at least 1 special character',
		es: 'La contraseña debe contener al menos 1 carácter especial', // LL
	},
	hi: {
		en: 'Hi',
		es: 'Hola', // LL
	},
	weAreSoGladYouAreHere: {
		en: 'We are glad you are here.',
		es: 'Nos complace que esté aquí', // LL
	},
	redirectToDocusign: {
		en: 'Redirect to Informed Consent Form',
		es: 'Volver al formulario de consentimiento informado', // LL
	},
	whatIsInformedConsent: {
		// used as a fallback only to study specific text
		en: 'What is an informed consent document?',
		es: '¿Qué es un documento de consentimiento informado?', // LL
	},
	// this will move to study configurable content, though could leave this as a fallback
	informedConsentText: {
		// used as a fallback only to study specific text
		en: 'An informed consent document is a concise and focused presentation of key information that will help potential participants understand why they might or might not want to be a part of a research study',
		es: 'Un documento de consentimiento informado es una presentación concisa y centrada en información clave que ayudará a los participantes potenciales a comprender por qué podrían o no querer ser parte de un estudio de investigación.', // LL
	},
	profileCreation: {
		en: 'Profile Creation',
		es: 'Creación del perfil', // LL
	},
	informedConsent: {
		en: 'Informed Consent',
		es: 'Consentimiento informado',
	},
	initialQuestionnaire: {
		en: 'Baseline Questionnaire',
		es: 'Cuestionario del punto de referencia', // LL
	},
	initialQuestionnaireOverview: {
		en: 'Baseline Questionnaire Overview',
		es: 'Resumen del cuestionario inicial', // LL
	},
	externalUserId: {
		en: 'External User Id',
		es: 'ID de usuario externo', // LL
	},
	sendEmailAddressResetEmail: {
		en: 'Send email to reset email address',
		es: 'Enviar correo electrónico para restablecer la dirección de correo electrónico', // LL
	},
	'resetEmail/resetEmailLinkIsSent': {
		en: 'To confirm your email, tap the link we sent to',
		es: 'Para confirmar su correo electrónico, toque el enlace que enviamos a', // LL
	},
	'resetEmail/doEmailReset': {
		// unused?
		en: 'Reset your email address',
		es: 'Restablecer su dirección de correo electrónico', // LL
	},
	resetEmail: {
		en: 'Reset Email',
		// es: 'Restablecer correo electrónico', // LL -- a prior translation
		es: 'Restablecer el correo electrónico', // LL
	},
	next: {
		en: 'Next',
		es: 'Siguiente', // LL
	},
	selectOneOrMore: {
		en: 'Select one or more',
		es: 'Seleccione uno o más', // LL
	},
	selectOne: {
		en: 'Select one',
		es: 'seleccione uno', // LL
	},
	answerOrNone: {
		en: 'Type your answer, or write "none"',
		es: 'Escriba su respuesta, o escriba "none" (ninguna)', // LL
	},
	selectADate: {
		en: 'Select a date',
		es: 'Seleccione una fecha', // LL
	},
	privacyPolicyContent: {
		en:
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">ACCEPTANCE OF TERMS</h2>' +
			'<p class="my-4 mx-0">Welcome to <DomainName> (the “Site”). Your privacy is important to us. It is our policy to protect your Personal Information (as defined below) and to use it only in accordance with this Privacy Policy. Using our Site is voluntary, and by using the Site, you consent to our collection and use of your identifying and non-identifying information, in the manner described in this Privacy Policy. This Privacy Policy only governs our use and disclosure of information collected through the Site.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">INFORMATION WE COLLECT FROM YOU</h2>' +
			'<p class="my-4 mx-0">We automatically collect information about your Internet connection, including but not limited to:</p>' +
			'<ul class="pl-4 list-disc list-inside"><li>The IP address you are using to access our Site;</li>' +
			'<li>The name of the site that linked to our Site, if any;</li>' +
			'<li>The date and time you accessed our Site; and</li>' +
			'<li>The pages you visited.</li></ul>' +
			'<p class="my-4 mx-0">This information (<strong class="text-[#505050]">“Non-Personal Information”</strong>) does not identify you personally. We use this Non-Personal Information to monitor the effectiveness of the Site and to consider potential improvements to the Site.</p>' +
			'<p class="my-4 mx-0">We may request that you provide certain personally identifiable information (<strong class="text-[#505050]">“Personal Information”</strong>) prior to you signing an informed consent form to potentially enroll in a study described on the Site.  Once you sign an informed consent form, the terms of that informed consent form will be used in the case of any conflict with the terms of this Privacy Policy.  In order to participate in a study, we may request that you allow us to pull your personal information from your electronic health record, including your name, e-mail address, and phone number. We do not use any Personal Information about you when you visit the Site unless you authorize us to do so.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">USE OF YOUR PERSONAL INFORMATION</h2>' +
			'<p class="my-4 mx-0">We use your Personal Information to help enroll you only in the stud(ies) in which you choose to participate. We will not sell, share, transfer or rent any Personal Information to others in ways different from what is disclosed in this statement and any informed consent form that you sign.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">SHARING YOUR INFORMATION</h2>' +
			'<p class="my-4 mx-0">We will only share your Personal Information if you enroll in a study. The specific terms and conditions of such use will be described and authorized by you in the informed consent form that you sign if you decide to participate in a study.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">USE OF COOKIES</h2>' +
			'<p class="my-4 mx-0">The use of cookies enables us to enhance your visits to the Site. Cookies are alphanumeric identifiers that are transferred to your computer’s hard drive through your web browser. The help portion of the toolbar on most browsers will tell you how to prevent your browser from accepting new cookies, but doing so may limit your ability to use some features of the Site. Cookies enable us to recognize your browser and provide features that are tailored to your needs.</p>' +
			'<p class="my-4 mx-0">We may use information from cookies for purposes of planning and tracking, as well as assessing the technical function of the Site. We may also use cookies to track information about which pages you visit within the Site to help us gauge the effectiveness of the Site or our content.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">LINKED SITES</h2>' +
			'<p class="my-4 mx-0">We may provide links on the Site to other Web sites of interest. Your use of such linked sites is subject to the privacy policies of the providers of those sites. We encourage our users to be aware when they leave our Site and to read the privacy statements of each Site that collects Personal Information.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">REVIEWING INFORMATION</h2>' +
			'<p class="my-4 mx-0">You may review your Personal Information at any time by contacting us. You may contact us via one of the contact methods described in the informed consent form if you wish to discuss any issues regarding your Personal Information.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">CHANGES</h2>' +
			'<p class="my-4 mx-0">We may change this Privacy Policy from time-to-time. If we do make a change, we will post the revised Privacy Policy on this Site. We encourage you to review this Privacy Policy periodically. Questions, comments or complaints about our privacy policies should be submitted to us via the methods described below or in any informed consent form that you sign.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">CONTACT INFORMATION</h2>' +
			'<p class="my-4 mx-0">You can also contact us directly at any time via StudyCoordinator@<DomainName> or <CoordinatorPhone>.</p>',
		// LL
		es:
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">ACEPTACIÓN DE LOS TÉRMINOS</h2>' +
			'<p class="my-4 mx-0">Bienvenido a <DomainName> (el “Sitio”). Su privacidad es importante para nosotros. Es nuestra política proteger su información personal (como se define abajo) y usarla solo de acuerdo con esta política de privacidad. Usar nuestro sitio es voluntario, y al usar el Sitio, usted da su consentimiento para que recopilemos y su información que lo identifica y que no lo identifica, en la forma descrita en esta política de privacidad. Esta política de privacidad solo rige nuestro uso y revelación de la información recopilada por medio del Sitio.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">INFORMACIÓN QUE RECOPILAMOS DE USTED</h2>' +
			'<p class="my-4 mx-0">Recopilamos automáticamente información sobre su conexión a Internet, incluyendo, entre otros:</p>' +
			'<ul class="pl-4 list-disc list-inside"><li>La dirección IP que está usando para acceder a nuestro Sitio;</li>' +
			'<li>Eel nombre del sitio que se vinculó a nuestro Sitio, si corresponde;</li>' +
			'<li>La fecha y hora en que accedió a nuestro Sitio; y</li>' +
			'<li>Las páginas que visitó.</li></ul>' +
			'<p class="my-4 mx-0">Esta información (<strong class="text-[#505050]">“Información no personal”</strong>) no lo identifica personalmente. Usamos esta información no personal para monitorear la eficacia del Sitio y considerar mejoras potenciales al Sitio.</p>' +
			'<p class="my-4 mx-0">Podemos pedirle que presente cierta información de identificación personal (<strong class="text-[#505050]">“Información personal”</strong>) antes de que firme un formulario de consentimiento informado para inscribirse potencialmente en un estudio descrito en el Sitio.  Una vez que firme un formulario de consentimiento informado, los términos de ese formulario de consentimiento informado se usarán en caso de cualquier conflicto con los términos de esta política de privacidad.  Para participar en un estudio, podemos pedirle que nos permita extraer su información personal del expediente médico electrónico, incluyendo su nombre, dirección de correo electrónico y teléfono. No usamos ninguna información personal sobre usted cuando visita el Sitio a menos que nos autorice a hacerlo.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">USO DE SU INFORMACIÓN PERSONAL</h2>' +
			'<p class="my-4 mx-0">Usamos su información personal para ayudar a inscribirlo solo en los estudios en los que decide participar. No venderemos, compartiremos, transferiremos ni alquilaremos ninguna información personal a otros de manera diferente a lo que se revela en esta declaración y en cualquier formulario de consentimiento informado que firme.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">CÓMO COMPARTIREMOS SU INFORMACIÓN</h2>' +
			'<p class="my-4 mx-0">Solo compartiremos su información personal si se inscribe en un estudio. Los términos y condiciones específicos de dicho uso los describirá y autorizará usted en el formulario de consentimiento informado que firme si decide participar en un estudio.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">EL USO DE COOKIES</h2>' +
			'<p class="my-4 mx-0">El uso de cookies nos permite mejorar sus visitas al Sitio. Las cookies son identificadores alfanuméricos que se transfieren al disco duro de su computadora por medio de su navegador web. La parte de ayuda de la barra de herramientas en la mayoría de los navegadores le indicará cómo evitar que su navegador acepte nuevas cookies, pero hacerlo puede limitar su capacidad para usar algunas funciones del Sitio. Las cookies nos permiten reconocer su navegador y darle funciones que se adaptan a sus necesidades.</p>' +
			'<p class="my-4 mx-0">Podemos usar la información de las cookies con fines de planificación y seguimiento, y para evaluar la función técnica del Sitio. También podemos usar las cookies para rastrear información sobre qué páginas visita en el Sitio para ayudarnos a medir la eficacia del Sitio o nuestro contenido.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">SITIOS VINCULADOS</h2>' +
			'<p class="my-4 mx-0">Podemos darle enlaces en el Sitio a otros sitios web de interés. El uso que usted haga de dichos sitios vinculados está sujeto a las políticas de privacidad de los proveedores de esos sitios. Animamos a nuestros usuarios a que estén atentos cuando salgan de nuestro Sitio y a que lean las declaraciones de privacidad de cada Sitio que recopila información personal.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">REVISIÓN DE LA INFORMACIÓN</h2>' +
			'<p class="my-4 mx-0">Puede revisar su información personal en cualquier momento comunicándose con nosotros. Puede comunicarse con nosotros por medio de uno de los métodos de contacto descritos en el formulario de consentimiento informado si quiere hablar sobre cualquier problema relacionado con su información personal.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">CAMBIOS</h2>' +
			'<p class="my-4 mx-0">Podemos cambiar esta política de privacidad de vez en cuando. Si hacemos un cambio, publicaremos la política de privacidad revisada en este Sitio. Lo animamos a que revise esta política de privacidad periódicamente. Las preguntas, los comentarios o las quejas sobre nuestras políticas de privacidad deben enviarse por medio de los métodos descritos abajo o en cualquier formulario de consentimiento informado que firme.</p>' +
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">INFORMACIÓN DEL CONTACTO</h2>' +
			'<p class="my-4 mx-0">También puede comunicarse con nosotros directamente en cualquier momento a StudyCoordinator@<DomainName> o al <CoordinatorPhone>.</p>',
	},
	unauthorized: {
		en: 'Unauthorized',
		es: 'No autorizado', // LL
	},
	cancel: {
		en: 'Cancel',
		es: 'Cancelar', // LL
	},
	signInWithGoogle: {
		en: 'Sign in with Google',
		es: 'Iniciar sesión con Google', // LL
	},
	'Invalid Email or password.': {
		en: 'Invalid Email or password.',
		es: 'Correo electrónico o contraseña no válidos.', // LL
	},
	'You have one more attempt before your account is locked.': {
		en: 'You have one more attempt before your account is locked.',
		es: 'Tiene un intento más antes de que se bloquee su cuenta.', // LL
	},
	'Your account is locked.': {
		en: 'Your account is locked.',
		es: 'Su cuenta está bloqueada.', // LL
	},
	'Your account is not activated yet.': {
		en: 'Your account is not activated yet.',
		es: 'Su cuenta aún no está activada.', // LL
	},
	'You need to sign in or sign up before continuing.': {
		en: 'You need to sign in or sign up before continuing.',
		es: 'Debe iniciar sesión o registrarse antes de continuar.', // LL
	},
	loggedOutMessage: {
		en: 'You have been signed out due to inactivity',
		es: 'Ha sido desconectado por inactividad', // LL
	},
	medication: {
		en: 'Medication',
		es: 'Medicamento', // LL
	},
	medications: {
		en: 'Medications',
		es: 'Medicamentos', // LL
	},
	vitamin: {
		en: 'Vitamin',
		es: 'Vitamina', // LL
	},
	vitamins: {
		en: 'Vitamins',
		es: 'Vitaminas', // LL
	},
	WhatsTheNameOfYour: {
		en: "What's the name of your medType?",
		es: '¿Cuál es el nombre de su medType?', // LL
	},
	activeMedication: {
		en: 'ACTIVE',
		es: 'ACTIVO', // LL
	},
	PleaseBeDescriptive: {
		en: 'Please be descriptive',
		es: 'Sea descriptivo', // LL
	},
	WhichVersionOfThisAreYouTaking: {
		en: 'Which version of this medType are you taking?',
		es: '¿Qué versión de este medType está tomando?', // LL
		// es: '¿Qué versión de su medType está tomando?', // LL -- a prior translation
	},
	CantFindYourMed: {
		en: 'Can’t find your medType?',
		es: '¿No puede encontrar su medType?', // LL
		// es: '¿No encuentra su medType?', // LL -- a prior translation
	},
	AddIt: {
		en: 'Add it',
		es: 'Agréguelo', // LL
	},
	WhenDidYouStartTakingThis: {
		en: 'When did you start taking this?',
		es: '¿Cuándo comenzó a tomar este?', // ? LL
	},
	WhenDidYouStopTakingThis: {
		en: 'When did you stop taking this?',
		es: '¿Cuándo dejó de tomar este?', // ? LL
	},
	yes: {
		en: 'Yes',
		es: 'Si',
	},
	AreYouStillTakingThis: {
		en: 'Are you still taking this?',
		es: '¿Sigue tomando este?', // ? LL
	},
	added: {
		en: 'added',
		es: 'agregado', // LL
	},
	medList: {
		en: 'medType list',
		es: 'Lista de medType', // LL
	},
	currentMedTypes: {
		en: 'Current medTypes',
		es: 'medTypes actuales', // LL
	},
	stoppedMedTypes: {
		en: 'Stopped medTypes',
		es: 'medTypes suspendidos', // LL
	},
	addAnother: {
		en: 'Add medType',
		es: 'Agregar medType',
		// es: 'añadir otro medType', // LL - a prior translation
	},
	thatsAllMyMeds: {
		en: "I'm not taking any medTypes",
		es: 'Ese es todo mi medType',
	},
	thanksForSettingUpMeds: {
		en: 'Thank You For Setting Up Your medTypes',
		es: 'Gracias por programar sus medTypes', // LL
	},
	letsSetUpYourMeds: {
		en: "Let's Set Up Your medTypes",
		es: 'Programemos sus medTypes', // LL
	},
	imNotTakingAnyMeds: {
		en: "I'm finished",
		es: 'Estoy finito',
	},
	medSearch: {
		en: 'medType Search',
		es: 'Buscar medType', // LL
	},
	useGenericOrBrand: {
		en: 'You can use either the generic or the brand name to search for your medTypes.',
		es: 'Puede usar el nombre genérico o la marca para buscar sus medTypes.', // LL
	},
	noMedsFound: {
		en: 'No medType found.',
		es: 'No se encontró el medType.', // LL
	},
	add: {
		en: 'Add',
		es: 'Agregar', // LL
	},
	started: {
		en: 'Started',
		es: 'Iniciado', // LL
	},
	stopped: {
		en: 'Stopped',
		es: 'Detenido', // LL
	},
	RemoveMed: {
		en: 'Remove medType',
		es: 'Quitar medType', // LL
	},
	RemovingThisMedIsPermanant: {
		en: 'Are you sure you want to remove this medType? This action cannot be undone.',
		es: '¿Está seguro de que quiere quitar este medType? Esta acción no se puede deshacer.', // LL
	},
	termsOfUse: {
		en: 'Terms of Use',
		es: 'Términos de uso', // LL
	},
	termsOfUseContent: {
		// THIS NEEDS TO BE MOVED TO BE STUDY SPECIFIC
		en:
			'<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">Website Terms of Use</h2> \
			<p class="my-4 mx-0">This Website, <DomainName> Terms and Conditions ("<strong class="text-[#505050]">Agreement</strong>") is a binding agreement between you ("<strong class="text-[#505050]">End User</strong>" or "<strong class="text-[#505050]">you</strong>") and Dorsata, Inc. ("<strong class="text-[#505050]">Dorsata</strong>"), on behalf of the Sponsor, AbbVie Inc. This Agreement governs your use of this Website. The Website is licensed, not sold, to you. BY USING THE WEBSITE, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE AUTHORIZED TO ENTER INTO A BINDING AGREEMENT; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT ACCESS AND/OR USE THE WEBSITE.</p> \
			<ol class="list-decimal pl-6 list-disc list-inside space-y-3"> \
				<li><u>License Grant</u>. Subject to the terms of this Agreement, Dorsata grants you a personal, limited, non-exclusive and nontransferable license to: \
					<ul class="pl-4 list-disc list-inside"> \
						<li>use the Website for your personal, non-commercial use via a web browser at <DomainName> strictly in accordance with the Website’s documentation; and</li> \
						<li>access, stream, download and use on such web browser the Content and Services (as defined in <strong class="text-[#505050]">Section 5</strong>) made available in or otherwise accessible through the Website, strictly in accordance with this Agreement and the Terms of Use applicable to such Content and Services as set forth in <strong class="text-[#505050]">Section 5</strong>.</i> \
					</ul> \
				</li> \
				<li><u>License Restrictions</u>.  Licensee shall not: \
					<ul class="pl-4 list-disc list-inside"> \
						<li>copy the Website, except as expressly permitted by this license;</li> \
						<li>modify, translate, adapt or otherwise create derivative works or improvements, whether or not patentable, of the Website;</li> \
						<li>reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or gain access to the source code of the Website or any part thereof;</li> \
						<li>remove, delete, alter or obscure any trademarks or any copyright, trademark, patent or other intellectual property or proprietary rights notices from the Website or documentation, including any copy thereof;</li> \
						<li>rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or otherwise make available the Website or any features or functionality of the Website, to any third party for any reason,</li> \
						<li>Remove, disable, circumvent or otherwise create or implement any workaround to any copy protection, rights management or security features in or protecting the Website; or</li> \
						<li>Upload or introduce any virus or malware to the Website.</li> \
					</ul> \
					<p>As a registered user of the Website, you may receive or establish an account (“Account”). You are solely responsible for maintaining the confidentiality and security of your Account. You should not reveal your Account information to anyone else, and must not use, or attempt to access or use anyone else’s Account. You are entirely responsible for all activities that occur on or through your Account, and you agree to immediately notify Dorsata of any unauthorized use of your Account or any other breach of security. Dorsata shall not be responsible for any losses arising out of unauthorized use of your Account. You are responsible for keeping your account log-in credentials (user name and password) confidential and not sharing them with unauthorized users. If you disclose your log-in credentials to someone, you are responsible for any use, disclosure, additions, deletions and modifications of your End User Data.</p> \
				</li> \
				<li><u>Reservation of Rights</u>. You acknowledge and agree that the Website is provided under license, and not sold, to you. You do not acquire any ownership interest in the Website under this Agreement, or any other rights thereto other than to use the Website in accordance with the license granted, and subject to all terms, conditions and restrictions, under this Agreement. Dorsata and its licensors and service providers reserve and shall retain their entire right, title and interest in and to the Website, including all copyrights, trademarks and other intellectual property rights therein or relating thereto, except as expressly granted to you in this Agreement.</li> \
				<li><u>Collection and Use of Your Information</u>. You acknowledge that when you use the Website, Dorsata may use automatic means (including, for example, cookies and web beacons) to collect information about your use of the Website. You also may be required to provide certain information about yourself as a condition to use the Website or certain of its features or functionality. All information we collect through or in connection with this Website is subject to our Privacy Policy. By using and providing information to or through this Website, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy. To the extent any terms or provisions of this Agreement, Privacy Policy and/or the Informed Consent Form (“ICF”) used in your clinical trial differ, the terms and conditions of the ICF shall control.</li> \
				<li><u>Content and Services</u>. The Website may provide you with access to an AbbVie Inc. website or websites (the "<strong class="text-[#505050]">Website(s)</strong>") and products and services accessible thereon, and certain features, functionality and content accessible on or through the Website may be hosted on such Website(s) (collectively, "<strong class="text-[#505050]">Content and Services</strong>"). Your access to and use of such Content and Services may be subject to such additional terms and conditions as may be posted on such Website(s), which terms and conditions are deemed incorporated herein by this reference. Any violation of such terms and conditions will also be deemed a violation of this Agreement. \
				<li><u>Geographic Restrictions</u>. The Content and Services are based in the United States. You acknowledge that you may not be able to access all or some of the Content and Services outside of the United States and that access thereto may not be legal by certain persons or in certain countries. If you access the Content and Services from outside the United States, you are responsible for compliance with local laws.</li> \
				<li><u>Updates</u>. Dorsata may from time to time in its sole discretion develop and provide Website updates, which may include upgrades, bug fixes, patches and other error corrections and/or new features (collectively, including related documentation, "<strong class="text-[#505050]">Updates</strong>"). Updates may also modify or delete in their entirety certain features and functionality. You agree that Dorsata has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality. You agree that all Updates will be deemed part of the Website and be subject to all terms and conditions of this Agreement.</li> \
				<li><u>Third Party Materials</u>. The Website may display, include or make available third-party content (including data, information, applications and other products services and/or materials) or provide links to third-party websites or services, including through third-party advertising ("<strong class="text-[#505050]">Third Party Materials</strong>"). You acknowledge and agree that Dorsata is not responsible for Third Party Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. Dorsata does not assume and will not have any liability or responsibility to you or any other person or entity for any Third Party Materials. Third Party Materials and links thereto are provided solely as a convenience to you and you access and use them at entirely at your own risk and subject to such third parties' +
			"'" +
			' terms and conditions.</li> \
				<li><u>Term and Termination</u>. \
					<ul class="pl-4 list-disc list-inside"> \
						<li>The term of Agreement commences when you access, for the first time, the Website and will continue in effect until terminated by you or Dorsata as set forth in this <strong class="text-[#505050]">Section 9</strong>.</li> \
						<li>You may terminate this Agreement by ceasing use of the Website and contacting your Clinical Study Investigator.</li> \
						<li>Dorsata may terminate this Agreement at any time without notice if it ceases to support the Website, which Dorsata may do in its sole discretion. In addition, this Agreement will terminate immediately and automatically without any notice if you violate any of the terms and conditions of this Agreement.</li> \
						<li>Upon termination: \
						<ul class="pl-4 list-disc list-inside"> \
							<li>all rights granted to you under this Agreement will also terminate; and</li> \
							<li>you must cease all use of the Website.</li> \
							<li>Termination will not limit any of Dorsata' +
			"'" +
			's rights or remedies at law or in equity.</li> \
						</ul> \
					</ul> \
				</li> \
				<li><u>Disclaimer of Warranties</u>. THE WEBSITE IS PROVIDED TO LICENSEE "AS IS" AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW DORSATA, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH RESPECT TO THE WEBSITE, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, DORSATA PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE WEBSITE WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. \
					<div>&nbsp;</div> \
					<div space-y-4> \
						SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU. \
					</div> \
				</li> \
				<li><u>Export Regulation</u>. The Website may be subject to US export control laws, including the US Export Administration Act and its associated regulations. You shall not, directly or indirectly, export, re-export or release the Website to, or make the Website accessible from, any jurisdiction or country to which export, re-export or release is prohibited by law, rule or regulation. You shall comply with all applicable federal laws, regulations and rules, and complete all required undertakings (including obtaining any necessary export license or other governmental approval), prior to exporting, re-exporting, releasing or otherwise making the Website available outside the US.</li> \
				<li><u>Severability</u>. If any provision of this Agreement is illegal or unenforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all other provisions of this Agreement will continue in full force and effect.</li> \
				<li><u>Entire Agreement</u>. This Agreement and our Privacy Policy constitute the entire agreement between you and Dorsata with respect to the Website and supersede all prior or contemporaneous understandings and agreements, whether written or oral, with respect to the Website.</li> \
				<li><u>Waiver</u>. No failure to exercise, and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of that or any other right hereunder. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.</li> \
				<li>Dorsata is entitled to transfer its rights and/or obligations under this Agreement to a third party without your prior written consent.</li> \
		',
		es: '<h2 class="p-0 mt-8 mx-0 mb-6 font-sans font-extrabold text-slate-900 text-[17px] leading-none">Términos de uso del sitio web</h2> \
			<p class="my-4 mx-0">Los términos y condiciones de este sitio web, <DomainName>, (el “<strong class="text-[#505050]">Acuerdo</strong>”) son un acuerdo vinculante entre usted (el “<strong class="text-[#505050]">Usuario final</strong>” o “<strong class="text-[#505050]">usted</strong>”) y Dorsata, Inc. ("<strong class="text-[#505050]">Dorsata</strong>"), en nombre del patrocinador, AbbVie Inc. Este acuerdo rige el uso que usted haga de este sitio web. A usted se le da una autorización para el sitio web; no se le vende. MEDIANTE EL USO DEL SITIO WEB, USTED (A) RECONOCE QUE LEYÓ Y ENTIENDE ESTE ACUERDO; (B) DECLARA QUE ESTÁ AUTORIZADO PARA FORMALIZAR UN ACUERDO VINCULANTE; Y (C) ACEPTA ESTE ACUERDO Y CONCUERDA EN QUE ESTÁ LEGALMENTE VINCULADO POR SUS TÉRMINOS. SI NO ESTÁ DE ACUERDO CON ESTOS TÉRMINOS, NO ACCEDA AL SITIO WEB NI LO USE.</p> \
			<ol class="list-decimal pl-6 list-disc list-inside space-y-3"> \
				<li><u>Concesión de licencia</u>. Sujeto a los términos de este Acuerdo, Dorsata le concede una licencia personal, limitada, no exclusiva y no transferible para: \
					<ul class="pl-4 list-disc list-inside"> \
						<li>usar el sitio web para su uso personal no comercial mediante un explorador web en <DomainName>  estrictamente de acuerdo con la documentación del sitio web, y</li> \
						<li>acceder, transmitir, descargar y usar en dicho explorador web el contenido y los servicios (según se definen en la <strong class="text-[#505050]">Sección 5</strong>)  que están disponibles en o de otra manera accesibles por medio de sitio web, estrictamente conforme a este Acuerdo y a los términos de uso aplicables a dicho contenido y servicios, según se estipula en la <strong class="text-[#505050]">Sección 5</strong>.</i> \
					</ul> \
				</li> \
				<li><u>Restricciones de la licencia</u>. El licenciatario no deberá: \
					<ul class="pl-4 list-disc list-inside"> \
						<li>copiar el sitio web, salvo según lo permite expresamente esta licencia;</li> \
						<li>modificar, traducir, adaptar ni de otra manera crear trabajos derivados o mejoras, sean o no patentables, del sitio web;</li> \
						<li>o hacer ingeniería inversa, desensamblar, descompilar, decodificar ni de otra manera intentar derivar u obtener acceso al código fuente del sitio web o cualquier parte de este; o quitar, borrar, alterar ni obscurecer cualesquiera marcas comerciales o cualesquiera avisos de derechos de autor, de marca comercial, de patente o de otra propiedad intelectual o de derechos propios del sitio web o de la documentación, incluyendo cualquier copia de estos;</li> \
						<li>alquilar, arrendar, dar en préstamo, vender, autorizar con licencia secundaria, ceder, distribuir, publicar, transferir ni de otra manera poner el sitio web o cualquier característica o funcionalidad del sitio web, disponible de cualquier tercero por cualquier motivo,</li> \
						<li>quitar, desactivar, esquivar ni de otra manera crear o implementar cualquier método alternativo para cualquier protección contra copia, manejo de derechos o característica de seguridad en el sitio web o que lo protejan; ni</li> \
						<li>subir ni introducir ningún virus ni malware al sitio web.</li> \
					</ul> \
					<p>Como usuario registrado del sitio web, usted puede recibir o establecer una cuenta (“Cuenta”). Usted es el único responsable de mantener la confidencialidad y seguridad de su cuenta. No debe revelar información de su cuenta a nadie más, y tampoco debe usar, ni intentar acceder o usar, la cuenta de alguien más. Usted se hace totalmente responsable de todas las actividades que ocurran en o por medio de su cuenta, y está de acuerdo en avisar inmediatamente a Dorsata de cualquier uso no autorizado de su cuenta o de cualquier otra violación de seguridad. Dorsata no se hará responsable de ninguna pérdida que surja del uso no autorizado de su cuenta. Usted se hace responsable de conservar la confidencialidad de las credenciales de inicio de sesión en su cuenta (nombre de usuario y contraseña) y de no compartirlas con usuarios no autorizados. Si revela sus credenciales de inicio de sesión con alguien, usted se hace responsable de cualquier uso, revelación, adición, eliminación y modificación de sus datos de usuario final.</p> \
				</li> \
				<li><u>Reservación de derechos</u>. Usted reconoce y acepta que el sitio web se da a usted bajo licencia y no en venta. No adquiere ningún interés de propiedad en el sitio web según este Acuerdo, ni ningún otro derecho en él distinto al uso del sitio web de acuerdo con la licencia concedida, y sujeto a todos los términos, las condiciones y restricciones, según este Acuerdo. Dorsata y sus licenciadores y proveedores de servicio se reservan todo su derecho, título e interés, y deberán conservarlos, en y para el sitio web, incluyendo todos los derechos de autor, marcas comerciales y otros derechos de propiedad intelectual en el sitio web o relacionados con este, salvo según se le conceda expresamente a usted en este Acuerdo.</li> \
				<li><u>Recopilación y uso de su información</u>. Usted acepta que, cuando use el sitio web, Dorsata puede usar medios automáticos (incluyendo, por ejemplo, cookies y monitores de acceso al sitio web) para recopilar información sobre el uso que hace del sitio web. También le pueden pedir que dé cierta información sobre usted mismo como condición para usar el sitio web o ciertas de sus características o funcionalidad. Toda la información que recopilamos por medio de, o en conexión con, este sitio web está sujeta a nuestra política de privacidad. Usando y dando información a, o por medio de, este sitio web, usted da su consentimiento a todas las acciones que tomemos con respecto a su información en cumplimiento de la política de privacidad. Hasta donde difiera cualquier término o disposición de este Acuerdo, de la política de privacidad o del formulario de consentimiento informado (“ICF”) que se usen en su ensayo clínico, los términos y la condiciones del ICF deberán regir.</li> \
				<li><u>Contenido y servicios</u>. El sitio web puede darle acceso a un sitio web de AbbVie Inc. o a sitios web (los “<strong class="text-[#505050]">Sitios web</strong>”), y productos y servicios accesibles en ellos, y ciertas características, funcionalidad y contenido accesible en o por medio del sitio web pueden alojarse en dichos sitios web (colectivamente, “<strong class="text-[#505050]">contenido y servicios</strong>”). Su acceso a dicho contenido y servicios, y el uso que haga de ellos, puede estar sujeto a los términos y condiciones de más que puedan publicarse en dichos sitios web, cuyos términos y condiciones se consideran incorporados en este documento por esta referencia. Cualquier infracción de dichos términos y condiciones también se considerará una infracción de este Acuerdo.</li> \
				<li><u>Restricciones geográficas</u>. El contenido y los servicios se prestan en los Estados Unidos. Usted reconoce que posiblemente no tenga acceso a todos o alguna parte del contenido y los servicios fuera de los Estados Unidos, y que el acceso a ellos posiblemente no sea legal para dichas personas o en ciertos países. Si accede al contenido y a los servicios desde el exterior de los Estados Unidos, usted se hace responsable de cumplir las leyes locales.</li> \
				<li><u>Información actualizada</u>. Dorsata, ocasionalmente y a su exclusiva discreción, puede desarrollar y presentar información actualizada del sitio web, que puede incluir mejoras, soluciones para virus, parches y otras correcciones de errores o características nuevas (colectivamente, incluyendo la documentación relacionada, la  "<strong class="text-[#505050]">Información actualizada</strong>"). La información actualizada también puede modificar o eliminar ciertas características y funcionalidad por completo. Usted acepta que Dorsata no tiene obligación en presentar ninguna información actualizada ni en continuar dando ni habilitando ninguna característica o funcionalidad en particular. Usted acepta que toda la información actualizada se considerará parte del sitio web y estará sujeta a todos los términos y condiciones de este acuerdo.</li> \
				<li><u>Material de terceros</u>. El sitio web puede mostrar, incluir o poner disponible contenido de terceros (incluyendo datos, información, aplicaciones y otros productos, servicios o material), o dar enlaces a sitios web o servicios de terceros, incluyendo publicidad de terceros ("<strong class="text-[#505050]">Material de terceros</strong>"). Usted acepta y acuerda que Dorsata no se hace responsable del material de terceros, incluyendo su exactitud, integridad, puntualidad, validez, cumplimiento de derechos de autor, legalidad, probidad, calidad o cualquier otro aspecto de dicho material. Dorsata no asume, ni tendrá, ninguna obligación ni responsabilidad ante usted o ante ninguna otra persona o entidad por cualquier material de terceros. El material de terceros y los enlaces a este se dan exclusivamente como una conveniencia para usted, y usted accede a ellos y los usa totalmente a su propio riesgo, y está sujeto a los términos y las condiciones de dichos terceros.</li> \
				<li><u>Plazo y finalización</u>. \
					<ul class="pl-4 list-disc list-inside"> \
						<li>El plazo del Acuerdo comienza cuando usted accede, por primera vez, al sitio web y continuará en vigor hasta que usted o Dorsata lo finalicen, según se estipula en esta <strong class="text-[#505050]">Sección 9</strong>.</li> \
						<li>Usted puede finalizar este Acuerdo dejando de usar el sitio web y comunicándose con el investigador de su estudio clínico.</li> \
						<li>Dorsata puede finalizar este Acuerdo en cualquier momento sin aviso, si este deja de patrocinar al sitio web, algo que Dorsata puede hacer a su exclusiva discreción. Además, este Acuerdo terminará inmediata y automáticamente sin ningún aviso, si usted infringe cualquiera de los términos y condiciones de este Acuerdo.</li> \
						<li>Con la finalización: \
						<ul class="pl-4 list-disc list-inside"> \
							<li>todos los derechos que se le concedieron según este Acuerdo también terminan; y</li> \
							<li>usted debe cesar todo uso del sitio web.</li> \
							<li>La finalización no limitará ninguno de los derechos ni recursos de Dorsata en cuanto a derecho o equidad.</li> \
						</ul> \
					</ul> \
				</li> \
				<li><u>Renuncia de responsabilidad sobre garantías</u>. EL SITIO WEB SE DA AL LICENCIATARIO “COMO ESTÁ” Y CON TODAS LAS FALLAS Y DEFECTOS SIN GARANTÍA DE NINGUNA CLASE. HASTA DONDE SE PERMITA SEGÚN LA LEY APLICABLE, DORSATA, EN NOMBRE PROPIO Y EN EL DE SUS AFILIADAS Y SUS RESPECTIVOS LICENCIADORES Y PROVEEDORES DE SERVICIO, EXIME EXPRESAMENTE DE RESPONSABILIDAD POR TODAS LAS GARANTÍAS, SEAN EXPRESAS, IMPLÍCITAS, ESTATUTARIAS O DE OTRO TIPO, CON RESPECTO AL SITIO WEB, INCLUYENDO TODAS LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD, IDONEIDAD PARA UN PROPÓSITO PARTICULAR, TÍTULO Y NO INFRACCIÓN, Y GARANTÍAS QUE PUEDAN SURGIR DEL CURSO DE TRANSACCIONES, EL CURSO DEL DESEMPEÑO, LA UTILIZACIÓN O LA PRÁCTICA COMERCIAL. SIN LIMITACIÓN A LO ANTES MENCIONADO, DORSATA NO DA NINGUNA GARANTÍA, NI HACE NINGUNA PROMESA, Y TAMPOCO HACE DECLARACIÓN DE NINGUNA CLASE SOBRE QUE EL SITIO WEB CUMPLIRÁ SUS REQUISITOS, LOGRARÁ CUALQUIER RESULTADO PREVISTO, SERÁ COMPATIBLE O FUNCIONARÁ CON CUALQUIER OTRO SOFTWARE, APLICACIONES, SISTEMAS O SERVICIOS, OPERARÁ SIN INTERRUPCIÓN, CUMPLIRÁ CUALQUIER ESTÁNDAR DE DESEMPEÑO O CONFIABILIDAD, O QUE ESTARÁ LIBRE DE ERRORES O QUE CUALQUIER ERROR O DEFECTO PUEDE CORREGIRSE O SER CORREGIRÁ. \
					<div>&nbsp;</div> \
					<div space-y-4> \
						ALGUNAS JURISDICCIONES NO PERMITEN LA EXCLUSIÓN DE, NI LAS LIMITACIONES SOBRE, GARANTÍAS IMPLÍCITAS O LAS LIMITACIONES SOBRE DERECHOS ESTATUTARIOS APLICABLES DE UN CONSUMIDOR, ASÍ QUE ALGUNAS O TODAS LAS EXCLUSIONES Y LIMITACIONES MENCIONADAS ARRIBA POSIBLEMENTE NO SE APLIQUEN A USTED. \
					</div> \
				</li> \
				<li><u>Reglamentación para exportación.</u>.  El sitio web puede estar sujeto a leyes de control de las exportaciones de EE. UU., incluyendo la US Export Administration Act (Ley de administración de las exportaciones de EE. UU.) y sus reglamentaciones asociadas. Usted no deberá, directa ni indirectamente, exportar, reexportar ni revelar el sitio web a, ni hacerlo accesible desde, cualquier jurisdicción o país a donde la exportación, reexportación o revelación sea prohibida por la ley, por normas o reglamentaciones. Usted deberá cumplir todas las leyes federales, reglamentaciones y normas aplicables, y deberá completar todos los trámites necesarios (incluyendo la obtención de cualquier licencia de exportación necesaria u otra aprobación gubernamental), antes de exportar, reexportar, revelar o de otra manera poner el sitio web a disponibilidad fuera de los EE. UU.</li> \
				<li><u>Divisibilidad</u>. Si alguna disposición de este Acuerdo es ilegal o no ejecutable según la ley aplicable, el resto de la cláusula se enmendará para que logre lo más cercanamente posible el efecto del término original, y todas las demás disposiciones de este Acuerdo continuarán en total vigor y efecto.</li> \
				<li><u>Acuerdo completo</u>. Este Acuerdo y nuestra política de privacidad constituyen todo el convenio entre usted y Dorsata con respecto al sitio web y sustituyen todos los entendimientos y acuerdos previos y contemporáneos, sea por escrito u orales, con respecto al sitio web.</li> \
				<li><u>Renuncia</u>. Ningún incumplimiento ni retraso de cualquiera de las partes en ejercer cualquier derecho o facultad amparados por este documento deberá funcionar como renuncia a estos, como tampoco ningún ejercicio único o parcial de ningún derecho o facultad amparados por este documento deberá impedir el ejercicio extra de ese derecho o de cualquier otro derecho amparado por este documento. En caso de que haya algún conflicto entre este Acuerdo y cualquier compra u otros términos aplicables, los términos de este Acuerdo deberán regir.</li> \
				<li>Dorsata tiene el derecho de transferir sus derechos u obligaciones según este Acuerdo a un tercero sin su consentimiento previo por escrito.</li> \
		',
	},
	adverseEventReported: {
		en: 'Adverse Event Reported',
		es: 'Acontecimiento Adverso informado',
	},
	hairlossReportedMethod: {
		en: 'Hairloss Reported Method',
		es: 'Hairloss Reported Method (nobody is ever going to see this Spanish title)',
	},
	solicited: {
		en: 'solicited',
		es: 'solicitado', // Yes, I guessed at the translation. Turns out I was right.
	},
	spontaneous: {
		en: 'spontaneous',
		es: 'espontáneo',
	},
	withdrawnPermissionForData: {
		en: 'Withdrawn Permission for Data?',
		es: '¿Se quitó el permiso para datos?', // LL
	},
	adminApprovedBaselinePhotoSubmissionDate: {
		en: 'Approved Baseline Photo Submission date',
		es: 'Fecha de envío de la fotografía de referencia aprobada',
	},
	adminPIEligibilitySignoffDate: {
		en: 'PI Eligibility Signoff Date',
		es: 'Fecha de aprobación de elegibilidad de PI',
	},
	entryListEmptyState: {
		en: 'No medTypes Entered',
		es: 'No se ingresaron medTypes', // LL
	},
	present: {
		en: 'Present',
		es: 'Presente', // LL
	},
	delete: {
		en: 'Delete',
		es: 'Borrar', // LL
	},
} as TranslationDictionary
