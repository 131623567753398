<template>
	<AskAQuestionModal
		:open="open"
		:embedded="false"
		@goToLogin="goToLogin"
		@closeModal="closeModal"
	/>

	<Disclosure v-slot="{ open }" as="nav" class="bg-header" v-if="!route.meta.admin">
		<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
			<div class="flex items-center justify-between h-16">
				<div class="flex-grow items-center justify-between max-w-xs">
					<router-link aria-label="Home Logo" :to="userStore.backToHomeUrl() || '/'">
						<span v-if="studyDefinitionStore.study_logo_svg">
							<i
								class="max-w-xxs md:max-w-3xl sm:h-6 md:h-6 lg:h-8 w-auto text-white"
								v-html="studyDefinitionStore.study_logo_svg"
							/>
						</span>
						<span v-if="!studyDefinitionStore.study_logo_svg">
							<default-dorsata-logo
								class="dorsata-logo block sm:h-6 md:h-6 lg:h-8 w-auto text-white"
								alt="Dorsata Logo"
								ref="navbarLogo"
							/>
						</span>
					</router-link>
				</div>
				<div class="flex gap-x-2 justify-between">
					<button
						@click="askAQuestionClicked(zen_desk_url)"
						class="ask-question-button rounded-full lg:py-1 lg:mt-1 lg:mb-0.5 md:mt-1 md:mb-0.5 sm:mt-1 sm:mb-0.5 md:py-1 sm:px-4 sm:py-1 sm:text-sm md:text-sm bg-accent-600 text-white shadow-sm hover:bg-accent-700 focus:outline-none focus:ring-2 focus:ring-accent-700 focus:ring-offset-2 inline-flex items-center"
					>
						<span class="sr-only">{{ t('askQuestionButton') }}</span>
						{{ t('askQuestionButton') }}
					</button>
					<!-- Mobile menu button -->
					<DisclosureButton
						data-cy="navbar-hamburger-menu-button"
						class="inline-flex items-center justify-center p-2 rounded-md text-white/90 border border-white/90 hover:text-white hover:bg-white/20 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
					>
						<span class="sr-only">Open main menu</span>
						<Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
						<XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
					</DisclosureButton>
				</div>
			</div>
		</div>

		<DisclosurePanel class="mx-auto md:max-w-7xl md:relative z-10">
			<div class="px-2 py-4 border-t border-white/20 md:absolute md:right-0 bg-header md:mr-6 rounded-b-xl md:w-screen md:max-w-sm">
				<div class="px-2 space-y-1 cursor-pointer">
					<div class="flex">
						<label for="language" class="text-white font-medium px-3 py-2 flex-grow-0">{{t('language')}}</label>
						<select
							id="language"
							data-cy="navbar-language-select"
							v-model="user.language"
							@change="displayLanguageOptions($event)"
							class="navbar-language-select w-full cursor-pointer inline-flex items-center justify-center rounded-md border border-white bg-primary-base text-white hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white focus:bg-primary-base flex-grow text-center"
						>
							<option data-cy="navbar-language-select-english" value="en">ENGLISH</option>
							<option data-cy="navbar-language-select-spanish" value="es">ESPAÑOL</option>
						</select>
					</div>
					<DisclosureButton
						as="div"
						v-if="displayFaqsOption(faqs_questions)"
						@click.prevent="goToFaqs(key)"
						class="block px-3 py-2 rounded-md font-medium text-white hover:bg-white-700"
					>
						{{ t('frequentlyAskedQuestions') }}
					</DisclosureButton>
					<DisclosureButton
						data-cy="navbar-menu-profile-button"
						as="div"
						v-if="userStore.user.id"
						@click.prevent="goToUserProfile()"
						class="block px-3 py-2 rounded-md font-medium text-white hover:bg-white-700"
					>
						{{ t('profile') }}
					</DisclosureButton>
					<DisclosureButton
						as="div"
						@click.prevent="goToPrivacyPolicy()"
						class="block px-3 py-2 rounded-md font-medium text-white hover:bg-white-700"
					>
						{{ t('privacyPolicy') }}
					</DisclosureButton>
					<DisclosureButton
						as="div"
						v-if="userStore.user.id"
						@click.prevent="logout()"
						class="block px-3 py-2 rounded-md font-medium text-white hover:bg-white-700"
					>
						{{ t('signOut') }}
					</DisclosureButton>
				</div>
			</div>
		</DisclosurePanel>
	</Disclosure>
</template>

<script setup lang="ts">
import {
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
} from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { logoutUser, LangLocaleChoice, FAQ } from '@/api'
import { goToZenDesk } from '@/zenDesk'
import { useRoute, useRouter } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import { useStudyDefinitionStore } from '@/stores/StudyDefinitionStore'
import { storeToRefs } from 'pinia'
import { ref, onMounted, reactive, computed, onBeforeUnmount } from 'vue'
import { useI18n } from '@/services/i18n/useI18n'
import AskAQuestionModal from '@/components/modals/AskAQuestionModal.vue'
import DefaultDorsataLogo from '@/components/icons/DefaultDorsataLogo.vue'

const studyDefinitionStore = useStudyDefinitionStore()
const userStore = useUserStore()
const router = useRouter()
const route = useRoute()
const { zen_desk_url, faqs_questions, key } = storeToRefs(studyDefinitionStore)

const navbarLogo = ref<HTMLImageElement | null>(null)
const { t } = useI18n()
const open = ref(false)

const isLockedToStudy = ref(false)

onMounted(() => {
	if (!key.value) {
		if (route.params.key) {
			studyDefinitionStore.hydrateStudyDefinition(route.params.key, route.query.locale)
		}
	}

	isLockedToStudy.value = document?.getElementById('app')?.dataset?.isLockedToStudy === 'true'

	getUserInfo()
})

interface iData {
	language: string | null
}

const user = reactive<iData>({
	language: null,
})
const isError = computed(() => route.meta.isError)

const logout = async () => {
	try {
		await logoutUser()
		await getUserInfo()
		goToLogin()
	} catch (err) {
		console.error(err)
	}
}

const getUserInfo = async () => {
	await userStore.hydrateUser()
	user.language = userStore.user.langLocale || 'en'
}

const goToLogin = () => {
	closeModal()
	router.push('/login')
}

const closeModal = () => {
	open.value = false
}

onBeforeUnmount(() => closeModal())

const displayFaqsOption = (faqs: FAQ[]) => {
	return (
		studyDefinitionStore.show_faqs_in_nav_bar &&
		(isLockedToStudy.value ||
			((userStore.user.token || route.params.patient_token) &&
				faqs.length &&
				!route.meta.isLanding))
	)
}

const goToFaqs = (key: string | null) => {
	router.push(`/faqs/${key}`)
}

const displayLanguageOptions = (event: { target: { value: LangLocaleChoice } }) => {
	userStore.updateLangLocale(event.target.value).finally(() => {
		// right now, this requires a reload to see the update, but in the future we could try to avoid this
		window.location.reload()
	})
}

const goToUserProfile = () => {
	router.push('/profile')
}

const goToPrivacyPolicy = () => {
	router.push(`/privacy_policy`)
}

const askAQuestionClicked = (url: string) => {
	if (userStore.user.id) {
		goToZenDesk(url)
	} else {
		open.value = true
	}
}

studyDefinitionStore.setStudyLogoSvg(null)
</script>

<style lang="scss">
.ask-question-button {
	line-height: 1;

	@media (max-width: 640px) {
		height: auto;
		width: auto;
		font-size: small;
		margin-bottom: 0.25rem;
		margin-top: 0.5rem;
		margin-left: 0.5rem;
		padding: 1rem 0.5rem;
		max-height: 1.5rem;
	}

	@media (max-width: 375px) {
		height: auto;
		width: auto;
		font-size: xx-small;
		margin-top: 0.5rem;
		margin-left: 0.5rem;
		padding: 0.5rem 0.5rem;
	}
}

.dorsata-logo {
	@media (max-width: 640px) {
		max-height: 1.5rem;
	}
}

// Adjust the Select Caret for the dark colored background
select.navbar-language-select {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
	background-position: right 0.5rem center;
	background-repeat: no-repeat;
	background-size: 1.5em 1.5em;
}
</style>
