import { defineStore } from 'pinia'
import {
	loginUser,
	signupUser,
	User,
	getUser,
	LangLocaleChoice,
	updateLanguageLocale,
	getStoredToken,
} from '../api'
import { THIRTY_MINUTES } from '../constants'

export type IUserState = {
	user: User
}

export const useUserStore = defineStore('UserStore', {
	// initial state
	state: () => {
		return {
			user: {
				email: null,
				id: null,
				token: getStoredToken() || null,
				role: null,
				consented: false,
				langLocale:
					(localStorage.getItem('langLocale') !== 'undefined' &&
						localStorage.getItem('langLocale')) ||
					navigator?.languages?.find((lang) => ['en', 'es'].includes(lang)) ||
					'en',
				dob: null,
				first_name: null,
				last_name: null,
				phone_number: null,
				jwt_refresh_timestamp: null,
				address_line_1: null,
				address_line_2: null,
				city: null,
				state: null,
				zipcode: null,
				loggedOutHome: localStorage.getItem('userLoggedOutHome'),
			},
		} as IUserState
	},

	// Same as methods in vue components
	actions: {
		hydrateUser() {
			return getUser().then((res) => {
				if (!res.id) {
					this.flush()
				} else {
					this.setUserAttributes(res)
				}
				return res
			})
		},
		flush() {
			this.setId(null)
			this.setEmail(null)
			this.setRole(null)
			this.setConsented(false)
			this.setDob(null)
			this.setLastName(null)
			this.setFirstName(null)
			this.setPhoneNumber(null)
			this.setAddressLine1(null)
			this.setAddressLine2(null)
			this.setCity(null)
			this.setState(null)
			this.setZipcode(null)
			this.setJwtRefreshTimestamp(null)
		},
		setUserAttributes(params: {
			id: number | null
			email: string | null
			role?: string | null
			consented: boolean | null
			langLocale: LangLocaleChoice
			dob: string | null
			first_name: string | null
			last_name: string | null
			phone_number: string | null
			address_line_1: string | null
			address_line_2: string | null
			city: string | null
			state: string | null
			zipcode: string | null
			jwt_refresh_timestamp: string | null
		}) {
			// params can be null in a bad password scenario
			if (params == null) return
			this.setToken()
			if (params.id) this.setId(params.id)
			if (params.email) this.setEmail(params.email)
			if (params.role) this.setRole(params.role)
			if (params.langLocale) this.setLangLocale(params.langLocale)
			if (params.dob) this.setDob(params.dob)
			if (params.first_name) this.setFirstName(params.first_name)
			if (params.last_name) this.setLastName(params.last_name)
			if (params.phone_number) this.setPhoneNumber(params.phone_number)
			if (params.address_line_1) this.setAddressLine1(params.address_line_1)
			if (params.address_line_2) this.setAddressLine2(params.address_line_2)
			if (params.city) this.setCity(params.city)
			if (params.state) this.setState(params.state)
			if (params.zipcode) this.setZipcode(params.zipcode)
			if (params.jwt_refresh_timestamp) this.setJwtRefreshTimestamp(params.jwt_refresh_timestamp)
		},
		async loginUser(params: { email: string; password: string }) {
			try {
				const response = await loginUser(params.email, params.password)

				this.setToken()
				this.setUserAttributes(response.data)
				this.clearLoggedOutHome()
				return response
			} catch (e) {
				console.error(e)
				return false
			}
		},
		async signupUser(params: { email: string; password: string }) {
			try {
				const response = await signupUser(params.email, params.password)
				this.setToken()
				this.setUserAttributes(response.data)
			} catch (e) {
				console.error(e)
			}
		},
		async updateLangLocale(newLang: LangLocaleChoice) {
			this.setLangLocale(newLang)
			return await updateLanguageLocale(newLang, getStoredToken() || null)
		},
		setToken() {
			if (getStoredToken()) {
				this.user.token = getStoredToken()
			} else {
				this.flush()
				this.user.token = null
			}
		},
		setEmail(newEmail: string | null) {
			this.user.email = newEmail
		},
		setId(newId: number | null) {
			this.user.id = newId
		},
		setRole(newRole: string | null) {
			this.user.role = newRole
		},
		setConsented(newBoolean: boolean) {
			this.user.consented = newBoolean
		},
		setLangLocale(newLangLocale: LangLocaleChoice) {
			const availableLanguages = ['en', 'es']
			if (availableLanguages.includes(newLangLocale)) {
				this.user.langLocale = newLangLocale
				localStorage.setItem('langLocale', newLangLocale)
			}
		},
		setDob(dob: string | null) {
			this.user.dob = dob
		},
		setFirstName(firstName: string | null) {
			this.user.first_name = firstName
		},
		setLastName(lastName: string | null) {
			this.user.last_name = lastName
		},
		setPhoneNumber(phoneNumber: string | null) {
			this.user.phone_number = phoneNumber
		},
		setAddressLine1(address: string | null) {
			this.user.address_line_1 = address
		},
		setAddressLine2(address: string | null) {
			this.user.address_line_2 = address
		},
		setCity(city: string | null) {
			this.user.city = city
		},
		setState(state: string | null) {
			this.user.state = state
		},
		setZipcode(zipcode: string | null) {
			this.user.zipcode = zipcode
		},
		setJwtRefreshTimestamp(jwt_refresh_timestamp: string | null) {
			this.user.jwt_refresh_timestamp = jwt_refresh_timestamp
		},
		clearLoggedOutHome() {
			localStorage.removeItem('loggedOutHome')
			this.user.loggedOutHome = '/'
		},
		setLoggedOutHome(loggedOutHomeUrl: string) {
			localStorage.setItem('loggedOutHome', loggedOutHomeUrl)
			this.user.loggedOutHome = loggedOutHomeUrl
		},
		backToHomeUrl(): string {
			if (this.isLoggedIn) return '/'

			return localStorage.getItem('loggedOutHome') || this.user.loggedOutHome || '/'
		},
	},

	// getters: same as computed properties in vue components
	getters: {
		isAdmin(): boolean {
			return this.user?.role?.includes('admin') || false
		},
		session_expiration_time(): number | null {
			return this.user.jwt_refresh_timestamp
				? new Date(this.user.jwt_refresh_timestamp).getTime() + THIRTY_MINUTES
				: null
		},
		isLoggedIn(): boolean {
			return !!this.user.id
		},
	},
})
