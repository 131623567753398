export const ALPHABET: string[] = 'abcdefghijklmnopqrstuvwxyz'
	.split('')
	.map((char) => char.toUpperCase())

export const zenDeskUrl: string = 'https://dorsatarwe.zendesk.com/hc/'

export const FIFTEEN_MINUTES = 15 * 60 * 1000
export const THIRTY_MINUTES = 30 * 60 * 1000

export const ONE_MINUTE = 1000 * 60
export const TWO_SECONDS = 1000 * 2

export const US_STATES: string[] = [
	'Alabama',
	'Alaska',
	'American Samoa',
	'Arizona',
	'Arkansas',
	'California',
	'Colorado',
	'Connecticut',
	'Delaware',
	'District of Columbia',
	'Florida',
	'Georgia',
	'Guam',
	'Hawaii',
	'Idaho',
	'Illinois',
	'Indiana',
	'Iowa',
	'Kansas',
	'Kentucky',
	'Louisiana',
	'Maine',
	'Maryland',
	'Massachusetts',
	'Michigan',
	'Minnesota',
	'Minor Outlying Islands',
	'Mississippi',
	'Missouri',
	'Montana',
	'Nebraska',
	'Nevada',
	'New Hampshire',
	'New Jersey',
	'New Mexico',
	'New York',
	'North Carolina',
	'North Dakota',
	'Northern Mariana Islands',
	'Ohio',
	'Oklahoma',
	'Oregon',
	'Pennsylvania',
	'Puerto Rico',
	'Rhode Island',
	'South Carolina',
	'South Dakota',
	'Tennessee',
	'Texas',
	'U.S. Virgin Islands',
	'Utah',
	'Vermont',
	'Virginia',
	'Washington',
	'West Virginia',
	'Wisconsin',
	'Wyoming',
]
