<script lang="ts" setup>
import Nav from './components/nav/Nav.vue'
import { useUserStore } from './stores/UserStore'
import { reactive, nextTick, watch, onUnmounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import ToastNotification from './components/modals/ToastNotification.vue'
import { ONE_MINUTE, TWO_SECONDS } from '@/constants'
const userStore = useUserStore()
const route = useRoute()
const router = useRouter()

// Make sure we hydrate the user from our local storage token on page reload
nextTick(() => userStore.hydrateUser())

// Determine if we're on a device that has hover capabilities
var canHover = !(matchMedia('(hover: none)').matches);
if (canHover) {
  document.body.classList.add('can-hover');
}

// user_id will always be undefined here, because it's `userStore.user.id` -- there is no `userStore.id`,
// but I'm not sure if that bug is necessary for the timeout logic as it is written, so I'm leaving it for now.
const { id, session_expiration_time } = storeToRefs(userStore)
const user_id = computed(() => id?.value)


// variables for toast notifications
interface iData {
	showToast: boolean
	toastMessage: string
	onClose: Function | undefined
}

const toastData = reactive<iData>({
	showToast: false,
	toastMessage: '',
	onClose: undefined,
})

// variables needed for session timeout checks
let oneMinuteCheck
let closeToTimeoutCheck
let timeoutWrapper
let closeToTimeoutCheckRunning = false

// an interval that runs every three seconds to see if it is time to make the api call to get the current user's login status
// this just runs at the time of jwt expiration in case we are a little off in timing and need to check the backend a couple of times
// before logging the user out on the front end
const clearingIntervals = () => {
	if (oneMinuteCheck) {
		clearInterval(oneMinuteCheck)
	}
	if (closeToTimeoutCheck) {
		clearInterval(closeToTimeoutCheck)
	}
	if (timeoutWrapper) {
		clearTimeout(timeoutWrapper)
	}
	closeToTimeoutCheckRunning = false
}

// this interval will run when we are nearing the jwt expiration time
const startCloseToTimeoutCheck = () => {
	clearingIntervals()
	closeToTimeoutCheck = setInterval(checkJwtExpiration, TWO_SECONDS)
	closeToTimeoutCheckRunning = true
}

// interval for checking users' login status on the backend when we reach the time at which their jwt token is set to expire
const startOneMinuteInterval = () => {
	clearingIntervals()
	oneMinuteCheck = setInterval(checkJwtExpiration, ONE_MINUTE)
}

const checkJwtExpiration = () => {
	let now = new Date()
	if (now >= session_expiration_time.value) {
		return checkForCurrentUser()
	}

	let timeLeft = session_expiration_time.value - now
	let aMinuteOrLessLeft = timeLeft <= ONE_MINUTE

	if (aMinuteOrLessLeft && !closeToTimeoutCheckRunning) {
		// If there's a minute or less until token expiration and the setTimeout hasn't begun yet,
		// start this setTimeout, which will run the under-a-minute-check starting when the jwt token is set to expire.
		// In case we are off in the timing, the under-a-minute interval will run every three seconds until the user is logged out.
		closeToTimeoutCheckRunning = true
		timeoutWrapper = setTimeout(function () {
			startCloseToTimeoutCheck()
		}, timeLeft + TWO_SECONDS)
	}
}

watch(
	() => session_expiration_time?.value,
	() => {
		// if the time the session is set to expire has been updated, clear current intervals and restart the one-minute interval
		startOneMinuteInterval()
	}
)
// Unless the user is logging in or going through signup steps, make a call to get the current user.
// If the response tells us that the user is not logged in on the back end, we send the user to the login page.
const checkForCurrentUser = () => {
	const notLoggingIn = !route.meta.isLogin || (route.meta.isLogin && user_id)
	if (notLoggingIn && !route.meta.ignoreSessionTimeout) {
		userStore.hydrateUser().then((res) => {
			if (!res.id) {
				// if the user is not logged in on the backend, redirect to login and clear both of intervals
				clearingIntervals()

				const queryParams = new URLSearchParams()
				queryParams.set('timeout', 'true')
				queryParams.set('last_route', route.fullPath)

				userStore.flush()

				router.push(`/login?${queryParams.toString()}`)
			}
		})
	}
}

const showToastNotification = ({ message, onClose }) => {
	toastData.showToast = true
	toastData.toastMessage = message
	toastData.onClose = onClose
}

watch(
	() => route.query.lang,
	(forceLangLocale) => {
		if (userStore.user.langLocale !== forceLangLocale) {
			userStore.updateLangLocale(forceLangLocale as 'en' | 'es')
		}
	}
)

watch(
	() => user_id,
	() => {
		// if the user is logged in, start the one minute interval
		if (user_id?.value) {
			startOneMinuteInterval()
		} else {
			// if the user is not logged in, make sure the intervals are cleared
			clearingIntervals()
		}
	}
)

onUnmounted(() => {
	clearingIntervals()
})
</script>

<template>
	<Nav />
	<ToastNotification
		:show="toastData.showToast"
		:message="toastData.toastMessage"
		:on-close="toastData.onClose"
	/>
	<router-view @show-toast-notification="showToastNotification" />
</template>
