import { createApp, markRaw, provide } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory, useRoute } from 'vue-router'
import { createPinia } from 'pinia'

const AdminPatientSurveyTable = () => import('./views/admin/AdminPatientSurveyTable.vue')
const UserProfile = () => import('./views/UserProfile.vue')
const LogIn = () => import('./views/registration/LogIn.vue')
const FourOhFour = () => import('./views/infra/FourOhFour.vue')
const FiveHundred = () => import('./views/infra/FiveHundred.vue')
const StudyQuestionnaire = () => import('./views/StudyQuestionnaire.vue')
const AdminUsersTable = () => import('./views/admin/AdminUsersTable.vue')
const VerifyUserProfile = () => import('./views/VerifyUserProfile.vue')
const DeclinedConfirmation = () => import('./views/DeclinedConfirmationPage.vue')
const DeclinedSuccessPage = () => import('./views/DeclinedSuccessPage.vue')
const PatientHomePage = () => import('./views/PatientHomePage.vue')
const ParticipantStatusPage = () => import('./views/splash_pages/ParticipantStatusPage.vue')
const RequestPasswordReset = () => import('./views/registration/RequestPasswordReset.vue')
const RequestEmailReset = () => import('./views/registration/RequestEmailReset.vue')
const ResetPassword = () => import('./views/registration/ResetPassword.vue')
const Faqs = () => import('./views/Faqs.vue')

const Landing = () => import('./views/Landing.vue')
const AdminUserEdit = () => import('./views/admin/AdminUserEdit.vue')
const InformedConsent = () => import('./views/InformedConsent.vue')
const DocusignConsentPage = () => import('./views/DocusignConsentPage.vue')
const PrivacyPolicy = () => import('./views/PrivacyPolicy.vue')
const termsOfUse = () => import('./views/TermsOfUse.vue')
const StudyProgressTrackingTable = () => import('./views/admin/StudyProgressTrackingTable.vue')

const AdminSidebarShell = () => import('./views/admin/AppShellWithSidebar.vue')
const AdminEnrollInvitedUsers = () => import('./views/admin/AdminEnrollInvitedUsers.vue')
const AdminStudyDefinitionsEdit = () => import('./views/admin/StudyDefinitionsEdit.vue')
const AdminDrugEntriesList = () => import('./views/admin/AdminDrugEntriesList.vue')
const AdminDrugEntryShow = () => import('./views/admin/AdminDrugEntryShow.vue')
const AdminReporting = () => import('./views/admin/StudyReports.vue')
const AdminHomePage = () => import('./views/admin/HomePage.vue')
const EmailTracking = () => import('./views/admin/EmailTracking.vue')
const EmailTrackingShow = () => import('./views/admin/EmailTrackingShow.vue')

import './index.css'

// Global State
const pinia = createPinia()
import { useUserStore } from './stores/UserStore'

// Router
const router = createRouter({
	scrollBehavior() {
		// always scroll to top
		return { top: 0 }
	},
	history: createWebHistory('/'),
	routes: [
		{ path: '/', name: 'PatientHomePage', component: PatientHomePage },
		{
			path: '/login',
			name: 'Login',
			component: LogIn,
			meta: {
				isLogin: true,
				absolutelyPositionedFooter: true,
			},
		},
		{
			path: '/password/edit',
			name: 'ResetPassword',
			component: ResetPassword,
		},
		{
			path: '/request_password_reset',
			name: 'RequestPasswordReset',
			component: RequestPasswordReset,
			meta: {
				absolutelyPositionedFooter: true,
			},
		},
		{
			path: '/request_email_reset',
			name: 'RequestEmailReset',
			component: RequestEmailReset,
		},
		{
			path: '/studies/:key',
			name: 'StudyQuestionnaire',
			component: StudyQuestionnaire,
			meta: { goToSpecificQuestion: false },
			children: [
				{
					path: ':question',
					component: StudyQuestionnaire,
					meta: { goToSpecificQuestion: true },
				},
			],
		},
		{
			path: '/verify_profile',
			name: 'VerifyUserProfile',
			component: VerifyUserProfile,
			meta: { isVerifyUserProfile: true, ignoreSessionTimeout: true },
		},
		{
			path: '/landing/:key/:patient_token',
			name: 'Landing',
			component: Landing,
			meta: { isLanding: true, ignoreSessionTimeout: true },
		},
		{
			path: '/informed_consent/:key/:patient_token',
			name: 'Informed Consent',
			component: InformedConsent,
			meta: { ignoreSessionTimeout: true },
		},
		{ path: '/success/decline/:key', name: 'DeclinedSuccessPage', component: DeclinedSuccessPage },
		{ path: '/faqs/:key', name: 'Faqs', component: Faqs },
		{ path: '/404', name: 'FourOhFour', component: FourOhFour },
		{ path: '/500', name: 'FiveHundred', component: FiveHundred, meta: { isError: true } },
		{ path: '/docusign_consent_page', name: 'DocusignConsentPage', component: DocusignConsentPage },
		{
			path: '/participation_status/:key/:status',
			name: 'ParticipantStatusPage',
			component: ParticipantStatusPage,
		},
		{
			path: '/confirmation/decline/:key/:patient_token',
			name: 'DeclinedConfirmation',
			component: DeclinedConfirmation,
		},
		{
			path: '/profile',
			name: 'Profile',
			component: UserProfile,
		},
		{
			path: '/privacy_policy',
			name: 'PrivacyPolicy',
			component: PrivacyPolicy,
		},
		{
			path: '/terms_of_use',
			name: 'TermsOfUse',
			component: termsOfUse,
		},
		{
			path: '/:pathMatch(.*)*',
			redirect: '/404',
		},
		{
			path: '/admin',
			name: 'AdminSidebarShell',
			component: AdminSidebarShell,
			beforeEnter: async () => {
				// Ensure user is an admin before loading the code to get there
				const userStore = useUserStore(pinia)
				await userStore.hydrateUser()
				if (!userStore.isAdmin) return '/'
			},
			meta: { admin: true },
			redirect: { name: 'AdminHomePage' },
			children: [
				{
					path: '',
					name: 'AdminHomePage',
					component: AdminHomePage,
				},
				{
					path: 'users',
					name: 'AdminUsersTable',
					component: AdminUsersTable,
				},
				{
					path: '/admin/profile/:user_id/edit',
					name: 'AdminUserEdit',
					component: AdminUserEdit,
				},
				{
					path: '/admin/studies/participant_progress',
					name: 'StudyProgressTrackingTable',
					component: StudyProgressTrackingTable,
				},
				{
					path: '/admin/survey_list',
					name: 'SurveyList',
					component: AdminPatientSurveyTable,
					meta: { requiresAdmin: true },
				},
				{
					path: '/admin/enroll_invited_users',
					name: 'AdminEnrollInvitedUsers',
					component: AdminEnrollInvitedUsers,
					meta: { requiresAdmin: true },
				},
				{
					path: '/admin/study_definitions',
					name: 'AdminStudyDefinitionsEdit',
					component: AdminStudyDefinitionsEdit,
					meta: { requiresAdmin: true },
				},
				{
					path: '/admin/drug_entries',
					name: 'AdminDrugEntriesList',
					component: AdminDrugEntriesList,
					meta: { requiresAdmin: true },
				},
				{
					path: '/admin/drug_entries/:questionAnswerId',
					name: 'AdminDrugEntryShow',
					component: AdminDrugEntryShow,
					meta: { requiresAdmin: true },
				},
				{
					path: '/admin/study_reports',
					name: 'AdminReporting',
					component: AdminReporting,
					meta: { requiresAdmin: true },
				},
				{
					path: '/admin/emails',
					name: 'EmailTracking',
					component: EmailTracking,
					meta: { requiresAdmin: true },
				},
				{
					path: '/admin/study_definition/:studyDefId/emails/:emailReceiptIdentifier',
					name: 'EmailTrackingShow',
					component: EmailTrackingShow,
					meta: { requiresAdmin: true },
				},
			],
		},
	],
})

// Add router to Pinia stores
pinia.use(({ store }) => {
	store.router = markRaw(router)
})

const ALLOWED_PARTICIPANT_SEEDING = process.env.VITE_ALLOW_CLINICAL_STUDY_PARTICIPANT_SEEDING == '1'
createApp(App)
	.use(router)
	.use(pinia)
	.provide('ALLOWED_PARTICIPANT_SEEDING', ALLOWED_PARTICIPANT_SEEDING)
	.mount('#app')

export { router }
